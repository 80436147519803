import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { PageLayout } from "../../page-layout";
import { PageLoader } from "../../page-loader";

import Hero from "../../Hero";
import CodeBlock from "../../CodeBlock";
import Pricing from "../../pricing/Pricing";
import FAQ from "../../faq/FAQ";
import ReadyToBuild from "../../ReadyToBuild";

function Auth0Login() {
    const { isLoading } = useAuth0();

    if (isLoading) {
        return <PageLoader />;
    }

    return (
        <PageLayout>
            <div className="login-page flex flex-col gap-[50px] md:gap-[100px]">
                <Hero />
                <CodeBlock />
                <Pricing />
                <FAQ />
                <ReadyToBuild />
            </div>
        </PageLayout>
    );
}

export default Auth0Login;
