import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../../page-layout";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PageLoader } from "../../page-loader";
import MoesifEmbeddedTemplate from "../../moesif/moesif-embedded-template";

const Auth0Dashboard = (props) => {
  const {
    user: auth0User,
    isLoading: auth0IsLoading,
    getAccessTokenSilently,
  } = useAuth0();

  const { fetchEmbedInfo } = props;

  let isLoading = auth0IsLoading;
  let user = auth0User;

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const checkout_session_id = searchParams.get("checkout-session");
  const [credits, setCredits] = useState("");
  const [isAccountReady, setAccountReady] = useState(true);
  const [error, setError] = useState();
  const [iFrameSrcLiveEvent, setIFrameSrcLiveEvent] = useState();
  const [iFrameSrcTimeSeries, setIFrameSrcTimeSeries] = useState();
  const [loading, setLoading] = useState(false);

  /// If we want to do Moesif dashboards later.
  // useEffect(() => {
  //   if (isLoading) {
  //     return <PageLoader />;
  //   }

  //   if(checkout_session_id) {
  //     fetch(`${process.env.REACT_APP_DEV_PORTAL_API_SERVER}/register/stripe/${checkout_session_id}`, {
  //       method: 'POST',
  //       headers: {
  //         // 'Authorization': should be the auth0 access token.
  //       }
  //     })
  //     .then(res => res.json())
  //     .then(
  //       (result) => {
  //         fetchEmbedInfo(result.customer, setIFrameSrcLiveEvent, setIFrameSrcTimeSeries, setError)
  //       }
  //     ).catch((err) => {
  //       setError(err);
  //     });
  //   }
  //   else {
  //     fetch(`${process.env.REACT_APP_DEV_PORTAL_API_SERVER}/stripe/customer?email=` + encodeURIComponent(user.email), {
  //       headers: {
  //         // 'Authorization': should be the auth0 access token when ready.
  //       }
  //     }).then(res => res.json())
  //     .then(
  //       (customer) => {
  //         fetchEmbedInfo(customer.id, setIFrameSrcLiveEvent, setIFrameSrcTimeSeries, setError);
  //       }
  //     ).catch((err) => {
  //       setError(err);
  //     });
  //   }
  // }, [isLoading, navigate, checkout_session_id, user, fetchEmbedInfo]);

  useEffect(() => {
    const checkBalance = async () => {
      setLoading(true);
      try {
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          ignoreCache: true,
        });

        const response = await fetch(
          `${process.env.REACT_APP_DEV_PORTAL_API_SERVER}/customer/balance`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        if (response.status === 202) {
          setAccountReady(false);
          return 0;
        }

        if (response.status === 200) {
          setAccountReady(true);
        }

        const balanceRes = await response.json();
        return balanceRes.balance;
      } catch (error) {
        throw error; // rethrow the error to propagate it to the caller
      } finally {
        setLoading(false);
      }
    };

    if (isLoading) {
      return <PageLoader />;
    }

    checkBalance()
      .then((balance) => setCredits(-balance / 2.5))
      .catch((err) => {
        setError(err);
      });

    const intervalId = setInterval(() => {
      if (!isLoading) {
        checkBalance()
          .then((balance) => setCredits(-balance / 2.5))
          .catch((err) => {
            setError(err);
          });
      }
    }, 10000); // 10 seconds
    return () => clearInterval(intervalId);
  }, [isLoading, user.email, getAccessTokenSilently, setCredits, setError]);

  return (
    <PageLayout>
      <>
        {/* <MoesifEmbeddedTemplate iFrameSrcLiveEvent={iFrameSrcLiveEvent} iFrameSrcTimeSeries={iFrameSrcTimeSeries} error={error} /> */}

        <section className="text-white mb-[5rem] flex justify-center items-center flex-col rounded-2xl">
          <span className="text-accent text-[7rem] font-bold">
            {/* <div className="flex flex-col items-start gap-4">
                          <span>{credits && credits}</span>
                          {loading || (!credits && <PageLoader />)}
                      </div> */}
            {loading ? (
              !!credits || credits === 0 ? (
                <div className="flex items-start gap-4">
                  <span>{credits}</span>
                  <PageLoader />
                </div>
              ) : (
                <PageLoader />
              )
            ) : credits || credits === 0 ? (
              credits
            ) : (
              <PageLoader />
            )}
          </span>
          <span className="font-bold">
            {!isAccountReady
              ? "Please wait while we finish setting up your account..."
              : "requests remaining"}
          </span>
        </section>
      </>
    </PageLayout>
  );
};

export default Auth0Dashboard;
