import FAQItem from "./FAQItem";
const FAQ_LIST = [
    {
        id: 1,
        question: "How quickly will I receive a result?",
        answer: "In general, all queries are returned in 3 to 4 seconds. Sometimes an individual request can take longer (5-7s) when we have to retry submitting it to multiple websites. We are monitoring the response times closely.",
    },
    {
        id: 2,
        question: "How many queries can I submit per second?",
        answer: "Our default rate limit for the Ultimate credits is 20 queries a minute. If your specific use case requires a higher concurrency we are able to change this limit.",
    },
    {
        id: 3,
        question: "Are the queries made in real-time?",
        answer: "Yes, whenever you call our API we directly query multiple websites for the latest results. We do not cache anything so the returned response will reflect the latest results from ARES",
    },
    // {
    //     id: 4,
    //     question: "Can I customize the search query location?",
    //     answer: "Yes, you are able to set the search location by specifying the country and language.",
    // },
    {
        id: 4,
        question: "What is your refund policy?",
        answer: "We offer a full refund within 7 days of your initial credit purchase unless you have used more than 20% of your credits.",
    },
];

const FAQ = () => {
    return (
        <section
            id="faq"
            className="max-md:scroll-my-10 flex flex-col gap-20 text-white"
        >
            <h2 className="text-[3.2rem] md:text-6xl text-center font-bold leading-[1.2] md:leading-[1.1]">
                Frequently Asked Questions
            </h2>

            <div className="flex max-w-[1388px] flex-col gap-[3.2rem]">
                {FAQ_LIST.map((item) => (
                    <FAQItem key={item.id} item={item} />
                ))}
            </div>
        </section>
    );
};
export default FAQ;
