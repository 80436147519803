import { Link } from "react-router-dom";
import FooterLogo from "../icons/FooterLogo";

const LINKS = [
    {
        id: 1,
        text: "Contact",
        href: "/contact-us",
    },
    {
        id: 2,
        text: "Terms",
        href: "/legal/terms",
    },
    {
        id: 3,
        text: "Privacy Policy",
        href: "/legal/privacy",
    },
];

const Footer = () => {
    return (
        <footer className="bg-[#191d20] py-[1rem] md:py-[2rem] w-full border-t border-border-light">
            <section className="text-body max-w-[1200px] mx-auto text-[12px] sm:text-[1.6rem] font-medium flex justify-between items-center max-sm:flex-wrap">
                <FooterLogo className="w-[154px] h-[40px] sm:w-[187px] sm:h-[48px]" />
                <p>
                    &copy; {new Date().getFullYear()}{" "}
                    <span className="sm:inline hidden"> traversaal.ai. </span>
                    All rights reserved.
                </p>

                <ul className="max-sm:w-full max-sm:border-t border-border-light md:gap-20 flex items-center justify-center">
                    {LINKS.map(({ id, text, href }) => (
                        <li className="hover:underline" key={id}>
                            <Link to={href}>{text}</Link>
                        </li>
                    ))}
                </ul>
            </section>
        </footer>
    );
};
export default Footer;
