import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
// import PageTitle from "@/components/PageTitle";
// import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs";
import QueryForm from "../../QueryForm";
import { PageLayout } from "../../page-layout";
import { useState } from "react";

// const code = `
// import requests

// url = "https://api-ares.traversaal.ai/live/predict"

// payload = { "query": ["taco spots in San Francisco"] }
// headers = {
//   "x-api-key": "my api key",
//   "content-type": "application/json"
// }

// response = requests.post(url, json=payload, headers=headers)
//     `;

const JSONCode = `
{
    "response_text": "Here are some taco spots in San Francisco:\n\n1. Al Pastor Papi: Known for their incredible creations inspired by Mexico City cuisine, Al Pastor Papi is a popular food truck in San Francisco.\n\n2. Cielito Lindo: Located in the Richmond neighborhood, Cielito Lindo serves traditional tacos and is famous for their quesabirria tacos.\n\n3. Cholita Linda: Don't overlook the tacos from Cholita Linda at the Ferry Building. Their crispy Baja-style fish taco is a standout.\n\n4. Don Pistos: Located in North Beach, Don Pistos offers deliciously authentic street tacos, including a favorite vegetarian option with squash.\n\n5. Garaje: Near Oracle Park, Garaje creates a retro drive-in vibe and serves tasty beer-battered tilapia tacos with chipotle mayo.\n\n6. La Taqueria: For a no-frills taco experience, visit La Taqueria in the Mission. Their tacos can be ordered \"super\" with beans, pico de gallo, three cheeses, sour cream, and avocado.\n\n7. Hook Fish Co.: If you're looking for the best fish tacos near Ocean Beach, try Hook Fish Co. Their fresh fish tacos, served grilled or poke style, have a kick from pickled slaw and spicy aioli.\n\n8. Mosto: Located in the Mission, Mosto is a bar known for its extensive tequila and mezcal selection. They also serve delicious al pastor and pastrami tacos.\n\n9. Tacko: Cow Hollow's Tacko offers some of the best tacos in the city, including grilled fish and gulf shrimp tacos filled with cilantro, cabbage, red onions, pico de gallo, and tomato salsa.\n\n10. Tacolicious: With locations in Marina and Mission, Tacolicious offers a variety of tacos, including traditional options like carne asada or carnitas, as well as vegan and gluten-free choices.\n\nPlease note that these recommendations are based on the information provided and may be subject to change.",
    "web_url": [
        "https://sf.eater.com/maps/best-tacos-san-francisco",
        "https://www.sftravel.com/article/where-to-find-best-tacos-san-francisco",
        "https://lataco.com/san-francisco-best-tacos-guide",
        "https://www.reddit.com/r/AskSF/comments/13gyfd7/taco_spot/",
        "https://www.yelp.com/search?find_desc=Best+Tacos&find_loc=San+Francisco%2C+CA",
        "https://www.femalefoodie.com/restaurant-reviews/best-tacos-in-san-francisco/",
        "https://www.unodostacos.com/",
        "https://www.yelp.com/search?find_desc=Tacos&find_loc=South+San+Francisco%2C+CA",
        "https://www.tiktok.com/@_charlenemay/video/7260334183686475050",
        "https://sfpanchovilla.com/",
        "https://www.sfchronicle.com/food/restaurants/article/copas-taqueria-tacos-sf-18297428.php",
        "https://traveloutlandish.com/blog/best-tacos-in-san-francisco-taquerias/",
        "https://www.theinfatuation.com/san-francisco/cuisines/tacos",
        "https://hauteliving.com/2017/02/best-tacos-in-sf-2017/629785/",
        "https://streettacosf.com/",
        "https://foursquare.com/top-places/san-francisco/best-places-street-tacos",
        "https://www.sfchronicle.com/projects/best-tacos-bay-area/"
    ]
}
`;

export default function Playground() {
    const [response, setResponse] = useState(JSONCode);

    return (
        <PageLayout>
            <section className="lg:p-8 flex min-h-screen">
                <div className="grow overflow-hidden">
                    <div className="lg:flex-row flex flex-col justify-center h-full gap-6">
                        <div className="w-full overflow-auto rounded-lg lg:max-w-[400px]">
                            <QueryForm setResponse={setResponse} />
                        </div>
                        <div className="flex-1 overflow-auto rounded-lg lg:p-6 lg:max-w-[800px]">
                            {/* <Tabs defaultValue="results">
                                <TabsList className="grid grid-cols-2 ml-8">
                                    <TabsTrigger
                                        className="text-2xl"
                                        value="results"
                                    >
                                        Results
                                    </TabsTrigger>
                                    <TabsTrigger
                                        className="text-2xl"
                                        value="code"
                                    >
                                        Code
                                    </TabsTrigger>
                                </TabsList>
                                <TabsContent className="w-full" value="results"> */}
                                    <div className="w-full p-4 rounded-lg">
                                        <SyntaxHighlighter
                                            language="json"
                                            style={atomOneDark}
                                            customStyle={{
                                                width: "100%",
                                                paddingInline: "20px",
                                                borderRadius: "10px",
                                            }}
                                            className="[&_code]:!whitespace-break-spaces"
                                        >
                                            {response}
                                        </SyntaxHighlighter>
                                    </div>
                                {/* </TabsContent>

                                <TabsContent className="w-full" value="code">
                                    <div className="w-full p-4 rounded-lg">
                                        <SyntaxHighlighter
                                            language="js"
                                            style={atomOneDark}
                                            customStyle={{
                                                width: "100%",
                                                paddingInline: "20px",
                                                borderRadius: "10px",
                                                
                                            }}
                                        >
                                            {code}
                                        </SyntaxHighlighter>
                                    </div>
                                </TabsContent>
                            </Tabs> */}
                        </div>
                    </div>
                </div>
            </section>
        </PageLayout>
    );
}
