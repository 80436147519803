import CheckIcon from "../../icons/CheckIcon";
import Button from "../Button";
import Modal from "../Modal";
import ContactForm from "../ContactForm";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useStripeContext } from "../../StripeProvider";
import {loadStripe} from '@stripe/stripe-js';


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);


const PricingCard = ({ list, title, price, period, active, onClick, buttonText, type }) => {
    
    const [isOpen, setIsOpen] = useState(false);
    const [purchaseModalOpen, setPurchaseModalOpen] = useState(false)
    const { loginWithRedirect, user } = useAuth0()
    const { clientSecret } = useStripeContext()
    
    const handleLogin = async () => {
        await loginWithRedirect({
            appState: {
                returnTo: "/dashboard",
            },
            authorizationParams: {
                prompt: "login",
                screen_hint: "signup",
            },
            scope: "openid profile email offline_access",
        });
    };

    const onClickMap = {
        TRIAL: handleLogin,
        ENTERPRISE: () => setIsOpen(true)
    }

    return (
        <div className="ring-1 ring-gray-100/60 min-w-[300px] rounded-[2.4rem] p-[3.2rem] shadow-lg">
            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <ContactForm />
            </Modal>

            <Modal isOpen={purchaseModalOpen} onClose={() => setPurchaseModalOpen(false)}>
              { clientSecret && user.email && 
                <div className="w-full max-w-2xl p-4 reounded shadow-md">
                  <stripe-buy-button
                    buy-button-id={process.env.REACT_APP_STRIPE_BUY_BUTTON_ID}
                    publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
                    customer-session-client-secret={clientSecret}
                  ></stripe-buy-button>
                </div>
              }

            </Modal>

            <div className="flex flex-col space-y-1.5">
                <h3 className="text-[2.4rem] font-semibold whitespace-nowrap leading-none tracking-tight">
                    {title}
                </h3>
            </div>
            <div className="mt-[2.4rem]">
                <h4 className="text-[3.6rem] font-bold my-4">
                    {price}
                    {period && <span className="text-[1.4rem]">/{period}</span>}
                </h4>
                <ul className="text-[1.4rem] mt-8 space-y-5">
                    {list.map((item) => {
                        return (
                            <li
                                key={item}
                                className="flex items-center space-x-2"
                            >
                                <CheckIcon />
                                <span>{item}</span>
                            </li>
                        );
                    })}
                </ul>
                { type !== 'STARTER' &&
                    <Button
                        text={buttonText}
                        disabled={!active}
                        className="w-full mt-[3rem] h-[4rem]"
                        onClick={() => {
                            const callback  = onClickMap[type];
                            callback && callback()
                        }}
                    />
                }

                { type === 'STARTER' && (
                    <Button
                        text={buttonText}
                        disabled={!clientSecret || !user.email}
                        className="w-full mt-[3rem] h-[4rem]"
                        onClick={() => {
                            setPurchaseModalOpen(true)
                        }}
                />
                )}
                
            </div>
        </div>
    );
};
export default PricingCard;
