import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

const CodeBlock = () => {
    const pythonCodeSnippet = `
import requests

url = "https://api-ares.traversaal.ai/live/predict"

payload = { "query": ["taco spots in San Francisco"] }
headers = {
  "x-api-key": "my api key",
  "content-type": "application/json"
}

response = requests.post(url, json=payload, headers=headers)
    `;

    return (
        <section className="relative mb-[4rem] md:mb-[10rem] overflow-hidden">
            <div className="flex items-center justify-center">
                <div className="sm:mt-12 md:mx-auto lg:w-2/3 lg:mx-0 lg:mt-0 mt-10">
                    <div className="absolute inset-y-0 right-1/2 -z-10 -mr-10 w-[200%] skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 md:-mr-20 lg:-mr-36" />
                    <div className="md:rounded-3xl mt-16 shadow-lg">
                        <div className="bg-accent bgHero [clip-path:inset(0)] md:[clip-path:inset(0_round_theme(borderRadius.3xl))]">
                            <div
                                className="absolute -inset-y-px left-1/2 -z-10 ml-10 w-[200%] skew-x-[-30deg] bg-indigo-100 opacity-20 ring-1 ring-inset ring-white "
                                aria-hidden="true"
                            />
                            <div className="sm:pt-16 md:pl-16 md:pr-0 relative max-md:pl-[11rem] max-md:pr-[2.4rem] pt-[3.2rem]">
                                <div className="md:mx-0 md:max-w-8xl max-w-4xl mx-auto">
                                    <div className="rounded-tl-xl w-screen overflow-hidden bg-[#282C34]">
                                        <div className="bg-gray-800/40 ring-1 ring-white/5 flex">
                                            <div className="-mb-px flex text-[1.4rem] font-medium leading-[2.4rem] text-gray-400">
                                                <span className="border-r border-gray-600/10 px-[16px] py-[8px]">
                                                    Main.py
                                                </span>
                                            </div>
                                        </div>
                                        <div className="bg-gray-800/40 ring-1 ring-white/5 flex">
                                            <SyntaxHighlighter
                                                language="python"
                                                style={atomOneDark}
                                                customStyle={{
                                                    paddingInline: "2rem",
                                                }}
                                            >
                                                {pythonCodeSnippet}
                                            </SyntaxHighlighter>
                                        </div>
                                    </div>
                                    <div
                                        className="ring-1 ring-inset ring-black/10 md:rounded-3xl absolute inset-0 pointer-events-none"
                                        aria-hidden="true"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default CodeBlock;
