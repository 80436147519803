import { useState } from "react";
import cn from "../../utils/cn";
import ChevronIcon from "../../icons/ChevronIcon";

const FAQItem = ({ item }) => {
    const [open, setOpen] = useState(false);

    return (
        <div
            onClick={() => setOpen((prev) => !prev)}
            className="flex cursor-pointer group flex-col max-w-[1388px] px-[1rem] md:px-[4.8rem] rounded-[2rem] border border-border-light"
        >
            <div className="flex items-center justify-between h-full">
                <h3
                    className={cn(
                        "text-[1.6rem] select-none text-white/90 group-hover:text-white md:text-[1.8rem] transition-all duration-100 font-bold max-md:leading-[1.6]",
                        open && "text-white"
                    )}
                >
                    {item.question}
                </h3>
                <span className="w-[40px] h-[40px] shrink-0 flex justify-center items-center cursor-pointer rounded-2xl">
                    <ChevronIcon
                        className={cn(
                            "w-[20px] h-[20px] transition-all duration-150 transform",
                            open && "rotate-180",
                            "text-white/90 group-hover:text-white"
                        )}
                    />
                </span>
            </div>
            <p
                className={cn(
                    "text-[1.5rem] md:text-[1.7rem]  md:pr-20 leading-[1.8] md:leading-loose transition-all duration-150 text-body",
                    open ? "max-h-[1000px]" : "max-h-0 overflow-hidden"
                )}
            >
                {item.answer}
            </p>
        </div>
    );
};

export default FAQItem;
