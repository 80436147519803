import Button from "../Button";
import InfoIcon from "../../icons/InfoIcon";
import { PageLayout } from "../page-layout";

const ErrorPage = ({ title, description, buttonText, buttonClick }) => {
    return (
        <PageLayout className="justify-center">
            <section className="px-20 max-w-4xl -mt-[10rem] p-8">
                <div className="p-8 text-center text-black bg-white rounded-lg">
                    <InfoIcon className="w-[4.8rem] h-[4.8rem] mx-auto mb-[1.6rem] text-accent" />
                    <h1 className="mb-4 text-[2rem] font-semibold">{title}</h1>
                    <p className="mb-8 text-[1.4rem] whitespace-pre-line">
                        {description}
                    </p>
                    {buttonText && (
                        <Button
                            className="w-8/12 mt-4"
                            text={buttonText}
                            onClick={buttonClick}
                        />
                    )}
                </div>
            </section>
        </PageLayout>
    );
};

export default ErrorPage;
