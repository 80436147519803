import { PageLayout } from "../../page-layout";
const PrivacyPolicy = () => {
    return (
        <PageLayout className="gap-[30px]">
            <h1 className="text-[3.2rem] md:text-6xl font-bold leading-[1.2] md:leading-[1.1]">
                Privacy Policy
            </h1>
            <p>Effective date: 5/15/2024</p>
            <p>
                At Traversaal.ai Inc. (“Traversaal”), we take your privacy
                seriously. Please read this Privacy Policy to learn how we treat
                your personal data. By using or accessing our Services in any
                manner, you acknowledge that you accept the practices and
                policies outlined below, and you hereby consent that we will
                collect, use and disclose your information as described in this
                Privacy Policy.
            </p>
            <p>
                Remember that your use of Traversaal’s Services is at all times
                subject to our API Terms and Conditions available at{" "}
                <a
                    className="text-accent"
                    href="https://api.traversaal.ai/legal/terms"
                >
                    https://api.traversaal.ai/legal/terms
                </a>
                , which incorporate this Privacy Policy. Any terms we use in
                this Policy without defining them have the definitions given to
                them in the API Terms and/or the Terms and Conditions. In the
                event of any conflict between this Privacy Policy and the API
                Terms and/or the Terms and Conditions, this Privacy Policy shall
                govern.
            </p>
            <p>
                As we continually work to improve our Services, we may need to
                change this Privacy Policy from time to time. We will alert you
                of material changes by placing a notice on the Traversaal
                website, by sending you an email and/or by some other means.
                Please note that if you’ve opted not to receive legal notice
                emails from us (or you haven’t provided us with your email
                address), those legal notices will still govern your use of the
                Services, and you are still responsible for reading and
                understanding them. If you use the Services after any changes to
                the Privacy Policy have been posted, that means you agree to all
                of the changes.
            </p>
            <h2 className="text-[2.4rem] md:text-4xl font-bold leading-[1.2] md:leading-[1.1]">
                Privacy Policy Table of Contents
            </h2>
            <ul className="md:pl-6 pl-3 space-y-2 list-disc list-inside">
                <li>What this Privacy Policy Covers</li>
                <li>Personal Data</li>
                <ul className="md:pl-6 pl-3 space-y-2 list-disc list-inside">
                    <li>Categories of Personal Data We Collect</li>
                    <li>
                        Our Commercial or Business Purposes for Collecting
                        Personal Data
                    </li>
                    <li>
                        Other Permitted Purposes for Processing Personal Data
                    </li>
                    <li>Categories of Sources of Personal Data</li>
                </ul>
                <li>How We Disclose Your Personal Data</li>
                <li>Tracking Tools, Advertising and Opt-Out</li>
                <li>Data Security</li>
                <li>Personal Data of Children</li>
                <li>State Law Privacy Rights</li>
                <li>Contact Information</li>
            </ul>
            <h3 className="text-[2rem] mb-4 md:text-4xl font-bold leading-[1.2] md:leading-[1.1]">
                What this Privacy Policy Covers
            </h3>
            <p>
                This Privacy Policy covers how we treat Personal Data that we
                gather when you access or use our Services. “Personal Data”
                means any information that identifies or relates to a particular
                individual and also includes information referred to as
                “personally identifiable information” or “personal information”
                or “sensitive personal information” under applicable data
                privacy laws, rules or regulations. This Privacy Policy does not
                cover the practices of companies we don’t own or control or
                people we don’t manage.
            </p>
            <h3 className="text-[2rem] mb-4 md:text-4xl font-bold leading-[1.2] md:leading-[1.1]">
                Personal Data
            </h3>
            <section className="flex flex-col gap-[10px]">
                <h4 className="text-[1.8rem] mb-4 md:text-[2rem] font-bold leading-[1.2] md:leading-[1.1]">
                    Categories of Personal Data We Collect
                </h4>
                <p>
                    This chart details the categories of Personal Data that we
                    collect and have collected over the past 12 months:
                </p>
                <table className="w-full mb-8 table-auto">
                    <thead>
                        <tr>
                            <th className="px-4 py-2 border">
                                Category of Personal Data (and Examples)
                            </th>
                            <th className="px-4 py-2 border">
                                Business or Commercial Purpose(s) for Collection
                            </th>
                            <th className="px-4 py-2 border">
                                Categories of Third Parties With Whom We
                                Disclose this Personal Data
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="px-4 py-2 border">
                                <span className="font-semibold">
                                    Profile or Contact Data{" "}
                                </span>
                                such as first and last name, email, and unique
                                identifiers.
                            </td>
                            <td className="px-4 py-2 border">
                                <ul className="space-y-2 list-disc list-inside">
                                    <li>
                                        Providing, Customizing and Improving the
                                        Services
                                    </li>
                                    <li>Marketing the Services</li>
                                    <li>Corresponding with You</li>
                                </ul>
                            </td>
                            <td className="px-4 py-2 border">
                                <ul className="space-y-2 list-disc list-inside">
                                    <li>Service Providers</li>
                                    <li>Business Partners</li>
                                    <li>
                                        Parties You Authorize, Access or
                                        Authenticate
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2 border">
                                <span className="font-semibold">
                                    Payment Data{" "}
                                </span>
                                such as financial account information, payment
                                card type, last 4 digits of payment card, and
                                billing address, phone number, and email.
                            </td>
                            <td className="px-4 py-2 border">
                                <ul className="space-y-2 list-disc list-inside">
                                    <li>
                                        Providing, Customizing and Improving the
                                        Services
                                    </li>
                                    <li>Corresponding with You</li>
                                </ul>
                            </td>
                            <td className="px-4 py-2 border">
                                Service Providers (specifically our payment
                                processing partner, currently Stripe, Inc.)
                            </td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2 border">
                                <span className="font-semibold">
                                    Device/IP Data{" "}
                                </span>
                                such as IP address, device ID, domain server,
                                and type of device/operating system/browser used
                                to access the Services.
                            </td>
                            <td className="px-4 py-2 border">
                                <ul className="space-y-2 list-disc list-inside">
                                    <li>
                                        Providing, Customizing and Improving the
                                        Services
                                    </li>
                                    <li>Marketing the Services</li>
                                    <li>Corresponding with You</li>
                                </ul>
                            </td>
                            <td className="px-4 py-2 border">
                                <ul className="space-y-2 list-disc list-inside">
                                    <li>Service Providers</li>
                                    <li>Business Partners</li>
                                    <li>
                                        Parties You Authorize, Access or
                                        Authenticate
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2 border">
                                <span className="font-semibold">
                                    Web Analytics{" "}
                                </span>
                                such as web page interactions, referring
                                webpage/source through which you accessed the
                                Services, non-identifiable request IDs, and
                                statistics associated with the interaction
                                between device or browser and the Services.
                            </td>
                            <td className="px-4 py-2 border">
                                <ul className="space-y-2 list-disc list-inside">
                                    <li>
                                        Providing, Customizing and Improving the
                                        Services
                                    </li>
                                    <li>Marketing the Services</li>
                                    <li>Corresponding with You</li>
                                </ul>
                            </td>
                            <td className="px-4 py-2 border">
                                <ul className="space-y-2 list-disc list-inside">
                                    <li>Service Providers</li>
                                    <li>Business Partners</li>
                                    <li>
                                        Parties You Authorize, Access or
                                        Authenticate
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2 border">
                                <span className="font-semibold">
                                    Social Network Data{" "}
                                </span>
                                such as email, phone number, user name, IP
                                address, and device ID.
                            </td>
                            <td className="px-4 py-2 border">
                                <ul className="space-y-2 list-disc list-inside">
                                    <li>
                                        Providing, Customizing and Improving the
                                        Services
                                    </li>
                                    <li>Corresponding with You</li>
                                </ul>
                            </td>
                            <td className="px-4 py-2 border">
                                <ul className="space-y-2 list-disc list-inside">
                                    <li>Service Providers</li>
                                    <li>Business Partners</li>
                                    <li>
                                        Parties You Authorize, Access or
                                        Authenticate
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2 border">
                                <span className="font-semibold">
                                    Inferences Drawn From Other Personal Data
                                    Collected{" "}
                                </span>
                                such as attributes, user behavior, and
                                predispositions.
                            </td>
                            <td className="px-4 py-2 border">
                                <ul className="space-y-2 list-disc list-inside">
                                    <li>
                                        Providing, Customizing and Improving the
                                        Services
                                    </li>
                                    <li>Marketing the Services</li>
                                    <li>Corresponding with You</li>
                                </ul>
                            </td>
                            <td className="px-4 py-2 border">
                                <ul className="space-y-2 list-disc list-inside">
                                    <li>Service Providers</li>
                                    <li>
                                        Parties You Authorize, Access or
                                        Authenticate
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2 border">
                                <span className="font-semibold">
                                    Other Identifying Information that You
                                    Voluntarily Choose to Provide,{" "}
                                </span>
                                including any information that you provide in
                                Search Requests, emails or other communications
                                you send us.
                            </td>
                            <td className="px-4 py-2 border">
                                <ul className="space-y-2 list-disc list-inside">
                                    <li>
                                        Providing, Customizing and Improving the
                                        Services
                                    </li>
                                    <li>Marketing the Services</li>
                                    <li>Corresponding with You</li>
                                </ul>
                            </td>
                            <td className="px-4 py-2 border">
                                <ul className="space-y-2 list-disc list-inside">
                                    <li>Service Providers</li>
                                    <li>Business Partners</li>
                                    <li>
                                        Parties You Authorize, Access or
                                        Authenticate
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </section>
            <section className="flex flex-col gap-[10px]">
                <h4 className="text-[1.8rem] mb-4 md:text-[2rem] font-bold leading-[1.2] md:leading-[1.1]">
                    Our Commercial or Business Purposes for Collecting Personal
                    Data
                </h4>
                <ul className="md:pl-6 pl-3 space-y-2 list-disc list-inside">
                    <li className="!mt-6 font-semibold">
                        Providing, Customizing and Improving the Services
                    </li>
                    <ul className="md:pl-6 pl-3 space-y-2 list-disc list-inside">
                        <li>
                            Creating and managing your account or other user
                            profiles.
                        </li>
                        <li>
                            Processing orders or other transactions; billing.
                        </li>
                        <li>
                            Providing you with the products, services or
                            information you request.
                        </li>
                        <li>
                            Meeting or fulfilling the reason you provided the
                            information to us.
                        </li>
                        <li>
                            Providing support and assistance for the Services.
                        </li>
                        <li>
                            Improving the Services, including testing, research,
                            internal analytics and product development.
                        </li>
                        <li>
                            Personalizing the Services, website content and
                            communications based on your preferences.
                        </li>
                        <li>Doing fraud protection, security and debugging.</li>
                        <li>
                            Carrying out other business purposes stated when
                            collecting your Personal Data or as otherwise set
                            forth in applicable data privacy laws.
                        </li>
                    </ul>
                    <li className="!mt-6 font-semibold">
                        Marketing the Services
                    </li>
                    <ul className="md:pl-6 pl-3 space-y-2 list-disc list-inside">
                        <li>Marketing and selling the Services.</li>
                    </ul>
                </ul>
                <li className="!mt-6 font-semibold">Corresponding with You</li>
                <ul className="md:pl-6 pl-3 space-y-2 list-disc list-inside">
                    <li>
                        Responding to correspondence that we receive from you,
                        contacting you when necessary or requested, and sending
                        you information about Traversaal or the Services.
                    </li>
                    <li>
                        Sending emails and other communications according to
                        your preferences.
                    </li>
                </ul>
            </section>

            <h4 className="text-[1.8rem] mb-4 md:text-[2rem] font-bold leading-[1.2] md:leading-[1.1]">
                Other Permitted Purposes for Processing Personal Data
            </h4>
            <p>
                In addition, each of the above referenced categories of Personal
                Data may be collected, used, and disclosed with the government,
                including law enforcement, or other parties to meet certain
                legal requirements and enforcing legal terms including:
                fulfilling our legal obligations under applicable law,
                regulation, court order or other legal process, such as
                preventing, detecting and investigating security incidents and
                potentially illegal or prohibited activities; protecting the
                rights, property or safety of you, Traversaal or another party;
                enforcing any agreements with you; responding to claims that any
                posting or other content violates third-party rights; and
                resolving disputes.
            </p>
            <p>
                We will not collect additional categories of Personal Data or
                use the Personal Data we collected for materially different,
                unrelated or incompatible purposes without providing you notice
                or obtaining your consent.
            </p>
            <section className="flex flex-col gap-[10px]"></section>
            <h4 className="text-[1.8rem] mb-4 md:text-[2rem] font-bold leading-[1.2] md:leading-[1.1]">
                Categories of Sources of Personal Data
            </h4>
            <p>
                We collect Personal Data about you from the following categories
                of sources:
            </p>
            <ul className="md:pl-6 pl-3 space-y-2 list-disc list-inside">
                <li className="!mt-6 font-semibold">You</li>
                <ul className="md:pl-6 pl-3 space-y-2 list-disc list-inside">
                    <li>
                        When you provide such information directly to us or
                        through your use of the Services.
                    </li>
                    <li>
                        When you create an account or use our interactive tools
                        and Services.
                    </li>
                    <li>
                        When you voluntarily provide information in Search
                        Requests, free-form text boxes through the Services or
                        through responses to surveys or questionnaires.
                    </li>
                    <li>When you send us an email or otherwise contact us.</li>
                    <li>
                        When you use the Services and such information is
                        collected automatically.
                    </li>
                    <li>
                        Through Cookies (defined in the “Tracking Tools,
                        Advertising and Opt-Out” section below).
                    </li>
                    <li>
                        If you use a location-enabled browser, we may receive
                        information about your location and mobile device, as
                        applicable.
                    </li>
                    <li>
                        If you download and install certain applications and
                        software we make available, we may receive and collect
                        information transmitted from your computing device for
                        the purpose of providing you the relevant Services, such
                        as information regarding when you are logged on and
                        available to receive updates or alert notices.
                    </li>
                </ul>
                <li className="!mt-6 font-semibold">Third Parties</li>
                <ul className="md:pl-6 pl-3 space-y-2 list-disc list-inside">
                    <li>Vendors</li>
                    <li>
                        We may use analytics providers to analyze how you
                        interact and engage with the Services, or third parties
                        may help us provide you with customer support.
                    </li>
                    <li>
                        We may use vendors to obtain information to generate
                        leads and create user profiles.
                    </li>
                </ul>
                <li className="!mt-6 font-semibold">Third-Party Credentials</li>
                <ul className="md:pl-6 pl-3 space-y-2 list-disc list-inside">
                    <li>
                        If you provide your third-party account credentials,
                        such as your social network account credentials, to us
                        or otherwise sign in to the Services through a
                        third-party site or service, some content and/or
                        information in those accounts may be transmitted into
                        your account with us.
                    </li>
                </ul>
            </ul>
            <h3 className="text-[2rem] mb-4 md:text-4xl font-bold leading-[1.2] md:leading-[1.1]">
                How We Disclose Your Personal Data
            </h3>
            <p>
                We disclose your Personal Data to the categories of service
                providers and other parties listed in this section. Depending on
                state laws that may be applicable to you, some of these
                disclosures may constitute a “sale” of your Personal Data. For
                more information, please refer to the state-specific sections
                below.
            </p>
            <h4 className="text-[1.8rem] mb-4 md:text-[2rem] font-bold leading-[1.2] md:leading-[1.1]">
                Service Providers
            </h4>
            <p>
                These parties help us provide the Services or perform business
                functions on our behalf. They include:
            </p>
            <ul className="md:pl-6 pl-3 space-y-2 list-disc list-inside">
                <li>Hosting, technology and communication providers.</li>
                <li>
                    Analytics providers for web traffic or usage of the site.
                </li>
                <li>Security and fraud prevention consultants.</li>
                <li>Support and customer service vendors.</li>
                <li>Payment processors.</li>
            </ul>
            <p>
                Our payment processing partner Stripe, Inc. (“Stripe”) collects
                your voluntarily-provided payment card information necessary to
                process your payment. Please see Stripe’s terms of service and
                privacy policy for information on its use and storage of your
                Personal Data.
            </p>
            <h4 className="text-[1.8rem] mb-4 md:text-[2rem] font-bold leading-[1.2] md:leading-[1.1]">
                Business Partners
            </h4>
            <p>
                These parties partner with us in offering various services. They
                include:
            </p>
            <ul className="md:pl-6 pl-3 space-y-2 list-disc list-inside">
                <li>Businesses that you have a relationship with.</li>
                <li>
                    Companies that we partner with to offer joint promotional
                    offers or opportunities.
                </li>
            </ul>
            <h4 className="text-[1.8rem] mb-4 md:text-[2rem] font-bold leading-[1.2] md:leading-[1.1]">
                Parties You Authorize, Access or Authenticate
            </h4>
            <ul className="md:pl-6 pl-3 space-y-2 list-disc list-inside">
                <li>Third parties you access through the services.</li>
                <li>Social media services.</li>
                <li>Other users.</li>
            </ul>
            <h4 className="text-[1.8rem] mb-4 md:text-[2rem] font-bold leading-[1.2] md:leading-[1.1]">
                Legal Obligations
            </h4>
            <p>
                We may disclose any Personal Data that we collect with third
                parties in conjunction with any of the activities set forth
                under “Other Permitted Purposes for Processing Personal Data”
                section above.
            </p>
            <h4 className="text-[1.8rem] mb-4 md:text-[2rem] font-bold leading-[1.2] md:leading-[1.1]">
                Business Transfers
            </h4>
            <p>
                All of your Personal Data that we collect may be transferred to
                a third party if we undergo a merger, acquisition, bankruptcy or
                other transaction in which that third party assumes control of
                our business (in whole or in part).
            </p>
            <h4 className="text-[1.8rem] mb-4 md:text-[2rem] font-bold leading-[1.2] md:leading-[1.1]">
                Data that is Not Personal Data
            </h4>
            <p>
                We may create aggregated, de-identified or anonymized data from
                the Personal Data we collect, including by removing information
                that makes the data personally identifiable to a particular
                user. We may use such aggregated, de-identified or anonymized
                data and disclose it with third parties for our lawful business
                purposes, including to analyze, build and improve the Services
                and promote our business, provided that we will not disclose
                such data in a manner that could identify you.
            </p>
            <h3 className="text-[2rem] mb-4 md:text-4xl font-bold leading-[1.2] md:leading-[1.1]">
                Tracking Tools, Advertising, and Opt-Out
            </h3>
            <p>
                The Services use cookies and similar technologies such as pixel
                tags, web beacons, clear GIFs and JavaScript (collectively,
                “Cookies”) to enable our servers to recognize your web browser,
                tell us how and when you visit and use our Services, analyze
                trends, learn about our user base and operate and improve our
                Services. Cookies are small pieces of data– usually text files –
                placed on your computer, tablet, phone or similar device when
                you use that device to access our Services. We may also
                supplement the information we collect from you with information
                received from third parties, including third parties that have
                placed their own Cookies on your device(s).
            </p>
            <p>
                Please note that because of our use of Cookies, the Services do
                not support “Do Not Track” requests sent from a browser at this
                time.
            </p>
            <p>We use the following types of Cookies:</p>
            <p>
                Essential Cookies. Essential Cookies are required for providing
                you with features or services that you have requested. For
                example, certain Cookies enable you to log into secure areas of
                our Services. Disabling these Cookies may make certain features
                and services unavailable.
            </p>
            <p>
                Functional Cookies. Functional Cookies are used to record your
                choices and settings regarding our Services, maintain your
                preferences over time and recognize you when you return to our
                Services. These Cookies help us to personalize our content for
                you, greet you by name and remember your preferences (for
                example, your choice of language or region).
            </p>
            <p>
                You can decide whether or not to accept Cookies through your
                internet browser’s settings. Most browsers have an option for
                turning off the Cookie feature, which will prevent your browser
                from accepting new Cookies, as well as (depending on the
                sophistication of your browser software) allow you to decide on
                acceptance of each new Cookie in a variety of ways. You can also
                delete all Cookies that are already on your device. If you do
                this, however, you may have to manually adjust some preferences
                every time you visit our website and some of the Services and
                functionalities may not work.
            </p>
            <p>
                To find out more information about Cookies generally, including
                information about how to manage and delete Cookies, please visit{" "}
                <a
                    href="http://www.allaboutcookies.org/"
                    className="hover:underline text-accent"
                >
                    http://www.allaboutcookies.org/
                </a>
                .
            </p>
            <h3 className="text-[2rem] mb-4 md:text-4xl font-bold leading-[1.2] md:leading-[1.1]">
                Data Security
            </h3>
            <p>
                We seek to protect your Personal Data from unauthorized access,
                use and disclosure using appropriate physical, technical,
                organizational and administrative security measures based on the
                type of Personal Data and how we are processing that data. You
                should also help protect your data by appropriately selecting
                and protecting your password and/or other sign-on mechanism;
                limiting access to your computer or device and browser; and
                signing off after you have finished accessing your account.
                Although we work to protect the security of your account and
                other data that we hold in our records, please be aware that no
                method of transmitting data over the internet or storing data is
                completely secure.
            </p>
            <h3 className="text-[2rem] mb-4 md:text-4xl font-bold leading-[1.2] md:leading-[1.1]">
                Data Retention
            </h3>
            <p>
                We retain Personal Data about you for as long as necessary to
                provide you with our Services or to perform our business or
                commercial purposes for collecting your Personal Data. When
                establishing a retention period for specific categories of data,
                we consider who we collected the data from, our need for the
                Personal Data, why we collected the Personal Data, and the
                sensitivity of the Personal Data. In some cases we retain
                Personal Data for longer, if doing so is necessary to comply
                with our legal obligations, resolve disputes or collect fees
                owed, or is otherwise permitted or required by applicable law,
                rule or regulation. We may further retain information in an
                anonymous or aggregated form where that information would not
                identify you personally.
            </p>
            <p>For example:</p>
            <ul className="md:pl-6 pl-3 space-y-2 list-disc list-inside">
                <li>
                    We retain your profile information and credentials for as
                    long as you have an account with us.
                </li>
                <li>
                    We retain your payment data for as long as we need to
                    process your purchase or subscription.
                </li>
                <li>
                    We retain your device/IP data for as long as we need it to
                    ensure that our systems are working appropriately,
                    effectively and efficiently.
                </li>
            </ul>
            <h3 className="text-[2rem] mb-4 md:text-4xl font-bold leading-[1.2] md:leading-[1.1]">
                Personal Data of Children
            </h3>
            <p>
                As noted in the Terms and Conditions, we do not knowingly
                collect or solicit Personal Data from children under 16 years of
                age; if you are a child under the age of 16, please do not
                attempt to register for or otherwise use the Services or send us
                any Personal Data. If we learn we have collected Personal Data
                from a child under 16 years of age, we will delete that
                information as quickly as possible. If you believe that a child
                under 16 years of age may have provided Personal Data to us,
                please contact us at{" "}
                <a
                    href="mailto:support@traversaal.ai"
                    className="hover:underline text-accent"
                >
                    support@traversaal.ai
                </a>
                .
            </p>
            <h3 className="text-[2rem] mb-4 md:text-4xl font-bold leading-[1.2] md:leading-[1.1]">
                State Law Privacy Rights
            </h3>
            <h4 className="text-[1.8rem] mb-4 md:text-[2rem] font-bold leading-[1.2] md:leading-[1.1]">
                California Resident Rights
            </h4>
            <p>
                Under California Civil Code Sections 1798.83-1798.84, California
                residents are entitled to contact us to prevent disclosure of
                Personal Data to third parties for such third parties’ direct
                marketing purposes; in order to submit such a request, please
                contact us at{" "}
                <a
                    href="mailto:support@traversaal.ai"
                    className="hover:underline text-accent"
                >
                    support@traversaal.ai
                </a>
                .
            </p>
            <p>
                Your browser may offer you a “Do Not Track” option, which allows
                you to signal to operators of websites and web applications and
                services that you do not wish such operators to track certain of
                your online activities over time and across different websites.
                Our Services do not support Do Not Track requests at this time.
                To find out more about “Do Not Track,” you can visit{" "}
                <a
                    href="http://www.allaboutdnt.com/"
                    className="hover:underline text-accent"
                >
                    www.allaboutdnt.com
                </a>
                .
            </p>
            <h4 className="text-[1.8rem] mb-4 md:text-[2rem] font-bold leading-[1.2] md:leading-[1.1]">
                Nevada Resident Rights
            </h4>
            <p>
                If you are a resident of Nevada, you have the right to opt-out
                of the sale of certain Personal Data to third parties. Please
                note that we do not currently sell your Personal Data as sales
                are defined in Nevada Revised Statutes Chapter 603A.
            </p>
            <h3 className="text-[2rem] mb-4 md:text-4xl font-bold leading-[1.2] md:leading-[1.1]">
                Contact Information
            </h3>
            <p>
                If you have any questions or comments about this Privacy Policy,
                the ways in which we collect and use your Personal Data or your
                choices and rights regarding such collection and use, please do
                not hesitate to contact us at:
            </p>
            <p>Traversaal.ai Inc. </p>
            <address>
                Traversaal.ai, Inc. <br />
                5960 S Land Park Dr #1420 <br />
                Sacramento, CA 95822
                <br />
                United States
            </address>
            <p>
                <a
                    href="https://api.traversaal.ai"
                    className="hover:underline text-accent"
                >
                    api.traversaal.ai
                </a>
                <br />
                <a
                    href="mailto:support@traversaal.ai"
                    className="hover:underline text-accent"
                >
                    support@traversaal.ai
                </a>
            </p>
        </PageLayout>
    );
};
export default PrivacyPolicy;
