import { useState } from "react";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import toast from "react-hot-toast";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
// import {
//     Select,
//     SelectContent,
//     SelectItem,
//     SelectTrigger,
//     SelectValue,
// } from "./ui/select";
// import { Switch } from "./ui/switch";

const QueryForm = ({ setResponse }) => {
    const [query, setQuery] = useState("");
    const [loading, setLoading] = useState(false);
    const { getAccessTokenSilently } = useAuth0();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!query) return;
        setLoading(true);
        setResponse("Loading...");

        try {

            const token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                ignoreCache: true,
            });
            const url = `${process.env.REACT_APP_DEV_PORTAL_API_SERVER}/playground`;
            const headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            };
            const payload = { query };

            const response = await axios.post(url, payload, {
                headers: headers,
            });

            setResponse(JSON.stringify(response.data, null, 2));
        } catch (error) {
            console.error("Error fetching data:", error);
            toast.error("Error fetching data");
        } finally {
            setLoading(false);
        }
    };

    return (
        <section className="bg-nav p-6 rounded-md">
            <div className="flex flex-col space-y-4">
                <form onSubmit={handleSubmit}>
                    <div>
                        <Label
                            className="block mb-2 text-2xl font-medium text-white"
                            htmlFor="query"
                        >
                            Query
                        </Label>
                        <Input
                            className="text-2xl"
                            id="query"
                            onChange={(e) => setQuery(e.target.value)}
                            placeholder="taco spots in San Francisco"
                        />
                    </div>
                    {/* <div>
                    <label
                        className="block mb-2 text-sm font-medium"
                        htmlFor="country"
                    >
                        Country
                    </label>
                    <Select>
                        <SelectTrigger id="country">
                            <SelectValue placeholder="United States (us)" />
                        </SelectTrigger>
                        <SelectContent position="popper">
                            <SelectItem value="us">
                                United States (us)
                            </SelectItem>
                            <SelectItem value="us">
                                United States (us)
                            </SelectItem>
                        </SelectContent>
                    </Select>
                </div>
                <div>
                    <label
                        className="block mb-2 text-sm font-medium"
                        htmlFor="locale"
                    >
                        Locale
                    </label>
                    <Select>
                        <SelectTrigger id="locale">
                            <SelectValue placeholder="English (en)" />
                        </SelectTrigger>
                        <SelectContent position="popper">
                            <SelectItem value="en">English (en)</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
                <div>
                    <Label
                        className="block mb-2 text-sm font-medium"
                        htmlFor="page"
                    >
                        Page
                    </Label>
                    <Input id="page" placeholder="1" />
                </div>
                <div>
                    <label
                        className="block mb-2 text-sm font-medium"
                        htmlFor="results"
                    >
                        Number of results
                    </label>
                    <Select>
                        <SelectTrigger id="results">
                            <SelectValue placeholder="10 (1 credit)" />
                        </SelectTrigger>
                        <SelectContent position="popper">
                            <SelectItem value="10">10 (1 credit)</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
                <div className="flex items-center space-x-2">
                    <Switch id="mini-batch" />
                    <Label htmlFor="mini-batch">
                        Mini-batch (up to 100 queries)
                    </Label>
                </div> */}
                    <div className="mt-4">
                        <Button size="lg" disabled={loading}>
                            Search
                        </Button>
                    </div>
                </form>
            </div>
        </section>
    );
};
export default QueryForm;
