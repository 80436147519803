import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../../icons/Logo.jsx";

export const NavBarBrand = () => {
    return (
        <div className="nav-bar__brand">
            <NavLink to="/">
                <Logo className="nav-bar__logo" />
            </NavLink>
        </div>
    );
};
