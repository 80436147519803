import cn from "../../utils/cn";
import * as React from "react";
const Input = React.forwardRef(({ className, type, ...props }, ref) => {
    return (
        <input
            type={type}
            className={cn(
                "flex h-16 w-full rounded-md border border-white/20 bg-transparent px-3 py-2 text-xl ring-offset-accent/60 file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-white/70 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-accent/30 focus-visible:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50",
                className
            )}
            ref={ref}
            {...props}
        />
    );
});
Input.displayName = "Input";

export { Input };
