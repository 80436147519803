import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../../icons/Logo";

export const MobileNavBarBrand = ({ handleClick }) => {
    return (
        <div onClick={handleClick} className="mobile-nav-bar__brand">
            <NavLink to="/">
                <Logo className="mobile-nav-bar__logo" />
            </NavLink>
        </div>
    );
};
