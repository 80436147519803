const Input = ({ label, type, ...props }) => {
    const commonInputProps = {
        ...props,
        className:
            "border placeholder:text-black/80 border-blackish/[0.08] focus:outline-none focus:border-blackish/50 rounded-[1rem] py-6 px-[2.5rem] text-lg",
    };

    return (
        <div className="flex flex-col gap-2">
            <label htmlFor={props.name} className="font-semibold">
                {label}{" "}
                {props.required && <span className="text-red-500">*</span>}
            </label>
            {type === "textarea" ? (
                <textarea {...commonInputProps} rows={props.rows || 4} />
            ) : (
                <input type={type || "text"} {...commonInputProps} />
            )}
        </div>
    );
};

export default Input;
