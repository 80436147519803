import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import CheckCircleIcon from "../icons/CheckCircleIcon";
import CreditCardIcon from "../icons/CreditCardIcon";
import { PageLoader } from "./page-loader";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import Button from "./Button";

const StripeConfirmationBlock = () => {
  const { user: auth0User, isLoading: auth0IsLoading } = useAuth0();

  let isLoading = auth0IsLoading;
  let user = auth0User;

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const checkout_session_id = searchParams.get("checkout_session_id");
  const [error, setError] = useState();
  const [checkoutInfo, setCheckoutInfo] = useState(null);

  useEffect(() => {
    if (!checkout_session_id) {
      navigate("/dashboard");
    }
  }, [checkout_session_id, navigate]);

  useEffect(() => {
    if (isLoading) {
      return <PageLoader />;
    }

    if (checkout_session_id) {
      fetch(
        `${process.env.REACT_APP_DEV_PORTAL_API_SERVER}/stripe/checkout/${checkout_session_id}`,
        {
          method: "GET",
          headers: {
            // 'Authorization': should be the auth0 access token.
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {
          const amount = result.amount;
          setCheckoutInfo({
            product_name: "Top Up",
            amount: amount,
            product_description: "$5 per 200 requests",
          });
        })
        .catch((err) => {
          setError(err);
        });
    }
  }, [isLoading, navigate, checkoutInfo, checkout_session_id, user]);


  return (
    checkoutInfo && (
      <div className="flex max-w-[89.6rem] flex-col md:flex-row mx-auto bg-white divide-y md:divide-x divide-gray-300 rounded-lg shadow-lg">
        <div className="flex flex-col items-center justify-center w-full md:w-1/2 p-[2.4rem] md:p-[4.8rem]">
          <div className="flex items-center mt-[0.8rem] mb-[2.4rem] space-x-4">
            <CreditCardIcon className=" w-[2.4rem] h-[2.4rem" />
            <h1 className="text-[1.8rem]  font-semibold">Traversaal.ai Inc</h1>
          </div>
          <h2 className="text-[3rem] font-medium">{checkoutInfo.product_name}</h2>
          <p className="my-[1rem] text-[4rem] font-bold text-accent">
            ${checkoutInfo.amount}
          </p>
          <p className=" text-gray-600">{checkoutInfo.product_description}</p>
          <div className="flex mt-[3.2rem] space-x-6 text-[1.4rem] text-gray-500">
            <span>Powered by stripe</span>
            <span>|</span>
            <Link className="hover:text-gray-700" to="#">
              Terms
            </Link>
            <span>|</span>
            <Link className="hover:text-gray-700" to="#">
              Privacy
            </Link>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center w-full md:w-1/2 p-[4.8rem]">
          <CheckCircleIcon className="text-accent w-[4.8rem] h-[4.8rem]" />
          <h1 className="mt-[1.6rem] text-center md:text-left text-[2.4rem] font-semibold">
            Thanks for your payment
          </h1>
          <p className="max-md:text-center mt-4 text-gray-600">
            A payment to TRAVERSAAI.AI INC will appear on your statement.
          </p>
          <div className="w-full p-[1.6rem] mt-[3.2rem] border-t border-dashed rounded-[0.8rem]">
            <div className="flex justify-between">
              <span className="font-medium">TRAVERSAAI.AI INC</span>
              <span className="font-medium">${checkoutInfo.amount}.00</span>
            </div>
          </div>
          <Button
            className="bg-transparent border border-accent text-accent hover:bg-accent hover:text-white mt-[3.2rem]"
            text="Go to Dashboard"
            onClick={() => {
              navigate("/dashboard");
            }}
          />
        </div>
      </div>
    )
  );
};
export default StripeConfirmationBlock;
