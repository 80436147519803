import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../page-layout";
import { PageLoader } from "../page-loader";
import Pricing from "../pricing/Pricing.jsx";

export default function Auth0StripeProducts() {
    const { user: auth0User, isLoading: auth0IsLoading } = useAuth0();

    const [isLoading, setIsLoading] = useState(auth0IsLoading);
    const [user, setUser] = useState(auth0User);

    // const [clientSecret, setClientSecret] = useState(null);
    // const [error, setError] = useState(null);

    // useEffect(() => {
    //   if (isLoading) {
    //     return;
    //   }

    //   fetch(
    //     `${process.env.REACT_APP_DEV_PORTAL_API_SERVER}/stripe/customer?email=` +
    //       encodeURIComponent(user.email),
    //     {
    //       headers: {
    //         // 'Authorization': should be the auth0 access token when ready.
    //       },
    //     }
    //   )
    //     .then((res) => res.json())
    //     .then((customer) => {
    //       fetch(
    //         `${process.env.REACT_APP_DEV_PORTAL_API_SERVER}/stripe/customer_session?customerId=` +
    //           encodeURIComponent(customer.id),
    //         {
    //           headers: {
    //             // 'Authorization': should be the auth0 access token when ready.
    //           },
    //         }
    //       )
    //         .then((res) => res.json())
    //         .then((client_secret) => {
    //           setClientSecret(client_secret);
    //         })
    //         .catch((err) => {
    //           setError(err);
    //         });
    //     })
    //     .catch((err) => {
    //       setError(err);
    //     });
    // }, [isLoading, user]);

    if (isLoading) {
        return <PageLoader />;
    }

    return (
        <PageLayout>
            <h1 className="text-[3.2rem] md:text-6xl font-bold leading-[1.2] md:leading-[1.1]">
                Billing
            </h1>
            <Pricing />

        </PageLayout>
    );
}
