import { PageLayout } from "../../page-layout";
import ContactForm from "../../ContactForm";
import CheckboxIcon from "../../../icons/CheckboxIcon";

const LIST = [
    "See a demo on how Traversaal helps companies understand and monetize API usage",
    "Discuss your API monetization and analytics goals and we can see how to best help you achieve them.",
];

const ContactUs = () => {
    return (
        <PageLayout>
            <h2 className="text-[3.2rem] md:text-6xl font-bold leading-[1.2] md:leading-[1.1]">
                Request Customized Pricing for your needs
            </h2>

            <ul className="flex flex-col gap-4">
                {LIST.map((item) => (
                    <li className="flex items-start md:items-center gap-4 text-[1.8rem]">
                        <CheckboxIcon className="text-accent shrink-0 max-md:size-8 size-7 max-md:mt-2" />
                        <span>{item}</span>
                    </li>
                ))}
            </ul>

            <div className="md:mb-[6rem] md:mt-[2rem]">
                <ContactForm />
            </div>
        </PageLayout>
    );
};
export default ContactUs;
