import { Routes, Route, Navigate, useSearchParams } from "react-router-dom";
import { useAuth0, Auth0Provider } from "@auth0/auth0-react";

import Login from "./components/pages/login/Login";
import Dashboard from "./components/pages/dashboard/Dashboard";
import Settings from "./components/pages/settings/Settings";
import StripeProducts from "./components/stripe/StripeProducts";
import Keys from "./components/pages/keys/Keys";
import { AuthenticationGuard } from "./components/authentication-guard";
import { StripeProvider } from "./StripeProvider";
import ConfirmationPage from "./components/pages/confirmation/ConfirmationPage";
import ContactUs from "./components/pages/contact-us/ContactUs";
import Terms from "./components/pages/terms/Terms";
import ErrorPage from "./components/pages/ErrorPage";
import Playground from "./components/pages/playground/Playground";
import PrivacyPolicy from "./components/pages/privacy/Privacy";

function App() {
    const { isAuthenticated } = useAuth0();
    const [searchParams] = useSearchParams();
    const error = searchParams.get("error");
    const error_description = searchParams.get("error_description");
    const { loginWithRedirect } = useAuth0();

    const handleLogin = async () => {
        await loginWithRedirect({
            appState: {
                returnTo: "/dashboard",
            },
            authorizationParams: {
                prompt: "login",
            },
            scope: "openid profile email offline_access",
        });
    };

    if (
        error === "access_denied" &&
        error_description === "Please verify your email before continuing."
    ) {
        return (
            <Auth0Provider>
                <ErrorPage
                    title="Email Verification Required"
                    description={`We just sent a verification email to your mail. 
                Click the link in the email to verify your account.`}
                    buttonText="Verified? Login"
                    buttonClick={handleLogin}
                />
            </Auth0Provider>
        );
    }

    if (process.env.REACT_APP_AUTH_PROVIDER === "Auth0") {
        return (
            <div className="App">
                <header className="App-header">
                    <StripeProvider>
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    !isAuthenticated ? (
                                        <Login />
                                    ) : (
                                        <Navigate replace to={"dashboard"} />
                                    )
                                }
                            />
                            <Route
                                path="product-select"
                                element={
                                    <AuthenticationGuard
                                        component={StripeProducts}
                                    />
                                }
                            />
                            <Route
                                path="dashboard"
                                element={
                                    <AuthenticationGuard
                                        component={Dashboard}
                                    />
                                }
                            />
                            <Route
                                path="settings"
                                element={
                                    <AuthenticationGuard component={Settings} />
                                }
                            />
                            <Route
                                path="keys"
                                element={
                                    <AuthenticationGuard component={Keys} />
                                }
                            />
                            <Route
                                path="confirmation"
                                element={
                                    <AuthenticationGuard
                                        component={ConfirmationPage}
                                    />
                                }
                            />
                            <Route
                                path="contact-us"
                                element={
                                    <AuthenticationGuard
                                        component={ContactUs}
                                    />
                                }
                            />
                            <Route path="legal/terms" element={<Terms />} />
                            <Route path="legal/privacy" element={<PrivacyPolicy />} />
                            <Route
                                path="playground"
                                element={
                                    <AuthenticationGuard
                                        component={Playground}
                                    />
                                }
                            />
                        </Routes>
                    </StripeProvider>
                </header>
            </div>
        );
    }
}

export default App;
