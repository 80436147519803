import React from "react";
import { NavLink } from "react-router-dom";

export const NavBarTab = ({ path, label, newTab }) => {
    return (
        <NavLink
            to={path}
            end
            target={newTab ? "_blank" : "_self"}
            className={({ isActive }) =>
                "nav-bar__tab " + (isActive ? "nav-bar__tab--active" : "")
            }
        >
            {label}
        </NavLink>
    );
};
