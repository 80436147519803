import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { NavBarTab } from "./nav-bar-tab";

function Auth0NavBarTabs() {
  const { isAuthenticated } = useAuth0();

  return (
      <div className="nav-bar__tabs">
          {isAuthenticated && (
              <>
                  <NavBarTab path="/dashboard" label="Dashboard" />
                  <NavBarTab path="/product-select" label="Billing" />
                  <NavBarTab path="/keys" label="Keys" />
                  <NavBarTab path="/playground" label="Playground" />
                  {/* <NavBarTab path="/settings" label="Settings" /> */}
                  <NavBarTab
                      path="https://docs.traversaal.ai/docs/intro"
                      label="Docs"
                      newTab
                  />
              </>
          )}
      </div>
  );
};

export default Auth0NavBarTabs
