import { createPortal } from "react-dom";
import CloseIcon from "../icons/CloseIcon";

const Modal = ({ isOpen, onClose, children }) => {
    const modalRoot = document.getElementById("modal-root");

    if (isOpen) {
        document.body.style.overflow = "hidden";
    } else {
        document.body.style.overflow = "visible";
    }

    return isOpen
        ? createPortal(
              <div
                  className="fixed top-0 left-0 z-[1000] flex items-center justify-center w-full h-full bg-gray-800 bg-opacity-75"
                  onClick={onClose}
              >
                  <div
                      className="relative z-[900] bg-white rounded-lg shadow-lg"
                      onClick={(e) => e.stopPropagation()}
                  >
                      <button
                          className="hover:text-gray-800 absolute top-0 right-0 p-2 text-gray-600"
                          onClick={onClose}
                      >
                          <CloseIcon />
                      </button>
                      {children}
                  </div>
              </div>,
              modalRoot
          )
        : null;
};

export default Modal;
