import { PageLayout } from "../../page-layout";
const Terms = () => {
    return (
        <PageLayout className="gap-[30px]">
            <h1 className="text-[3.2rem] md:text-6xl font-bold leading-[1.2] md:leading-[1.1]">
                Terms and Conditions
            </h1>

            <p>
                These Terms and Conditions (“Terms” or “Terms and Conditions”)
                govern
            </p>
            <ul className="md:pl-6 pl-3 space-y-2 list-disc list-inside">
                <li>
                    your (“you”, “your”, or “User”) use of this Website
                    (api.traversaal.ai and related domains), and,
                </li>
                <li>
                    any other related Agreement or legal relationship with
                    traversaal.ai (Traversaal.ai, Inc.)
                </li>
                <li>
                    your purchase of Search API services (see special Search API
                    Terms below) for terms related to Search API Services.
                </li>
            </ul>
            <p>
                in a legally binding way. Capitalized words are defined in the
                relevant dedicated section of this document.
            </p>

            <p className="my-8">The User must read this document carefully.</p>
            <p className="-my-4">This Website is provided by:</p>
            <address>
                Traversaal.ai, Inc. <br />
                5960 S Land Park Dr #1420 <br />
                Sacramento, CA 95822
                <br />
                United States
            </address>
            <p>
                Owner contact email:{" "}
                <a className="text-accent" href="mailto:support@traversaal.ai">
                    support@traversaal.ai
                </a>
            </p>
            <h2 className="text-[2.4rem] md:text-4xl font-bold leading-[1.2] md:leading-[1.1]">
                What the User should know at a glance
            </h2>
            <p>
                Please note that some provisions in these Terms may only apply
                to certain categories of Users. In particular, certain
                provisions may only apply to Consumers or to those Users that do
                not qualify as Consumers. Such limitations are always explicitly
                mentioned within each affected clause. In the absence of any
                such mention, clauses apply to all Users.
            </p>
            <section className="flex flex-col gap-[1.6rem]">
                <h2 className="text-[2rem] mb-4 md:text-4xl font-bold leading-[1.2] md:leading-[1.1]">
                    TERMS OF USE
                </h2>
                <p>
                    Unless otherwise specified, the terms of use detailed in
                    this section apply generally when using this Website.
                </p>
                <p>
                    Single or additional conditions of use or access may apply
                    in specific scenarios and in such cases are additionally
                    indicated within this document.
                </p>
                <p>
                    By using this Website, Users confirm to meet the following
                    requirements:
                </p>
                <ul className="md:pl-6 pl-3 space-y-2 list-disc list-inside">
                    <li>
                        There are no restrictions for Users in terms of being
                        Consumers or Business Users;
                    </li>
                </ul>
            </section>

            <section className="flex flex-col gap-[1.6rem]">
                <h3 className="text-[1.8rem] mb-4 md:text-[2rem] font-bold leading-[1.2] md:leading-[1.1]">
                    Content on this Website
                </h3>
                <p>
                    Unless where otherwise specified or clearly recognizable, as
                    between the parties, all content available on this Website
                    is owned or provided by the Owner or its licensors.
                </p>
                <p>
                    The Owner believes that the content provided on this Website
                    infringes no applicable legal provisions or third-party
                    rights. However, it may not always be possible to achieve
                    such a result. In such cases, without prejudice to any legal
                    prerogatives of Users to enforce their rights, Users are
                    kindly asked to preferably report related complaints using
                    the contact details provided in this document.
                </p>
            </section>
            <section className="flex flex-col gap-[1.6rem]">
                <h3 className="text-[1.8rem] mb-4 md:text-[2rem] font-bold leading-[1.2] md:leading-[1.1]">
                    Rights regarding content on this Website - All rights
                    reserved
                </h3>
                <p>
                    The Owner holds and reserves all intellectual property
                    rights for any such content.
                </p>
                <p>
                    Users may not therefore use such content in any way that is
                    not necessary or implicit in the proper use of the Service.
                </p>
                <p>
                    In particular, but without limitation, Users may not copy,
                    download, share (beyond the limits set forth below), modify,
                    translate, transform, publish, transmit, sell, sublicense,
                    edit, transfer, or assign to third parties or create
                    derivative works from the content available on this Website,
                    nor allow any third party to do so through the User or their
                    device, even without the User's knowledge.
                </p>
                <p>
                    Where explicitly stated on this Website, the User may
                    download, copy, or share some content available through this
                    Website for your sole personal and non-commercial use and
                    provided that the copyright attributions and all the other
                    attributions requested by the Owner are correctly
                    implemented.
                </p>
                <p>
                    Any applicable statutory limitation or exception to
                    copyright shall stay unaffected.
                </p>
            </section>
            <section className="flex flex-col gap-[1.6rem]">
                <h3 className="text-[1.8rem] mb-4 md:text-[2rem] font-bold leading-[1.2] md:leading-[1.1]">
                    Age Requirements
                </h3>
                <p>
                    If you’re under the age of 18 or under the age required in
                    your home state or country to legally manage your own
                    api.traversaal.ai account, you must have your parent or
                    legal guardian’s permission to use api.traversaal.ai. Please
                    have your parent or legal guardian read these terms with
                    you. If you’re a parent or legal guardian, and you allow
                    your child to use the api.traversaal.ai’s services, then
                    these terms apply to you and you’re responsible for your
                    child’s activity on the services. Some api.traversaal.ai
                    services may have additional age requirements as described
                    in the terms for those services.
                </p>
            </section>
            <section className="flex flex-col gap-[1.6rem]">
                <h3 className="text-[1.8rem] mb-4 md:text-[2rem] font-bold leading-[1.2] md:leading-[1.1]">
                    Access to external resources
                </h3>
                <p>
                    Through this Website, Users may have access to external
                    resources provided by third parties. Users acknowledge and
                    accept that the Owner has no control over such resources and
                    is therefore not responsible for their content and
                    availability.
                </p>
                <p>
                    Conditions applicable to any resources provided by third
                    parties, including those applicable to any possible grant of
                    rights in content, result from each third parties’ terms and
                    conditions or, in the absence of those, applicable law.
                </p>
            </section>

            <section className="flex flex-col gap-[1.6rem]">
                <h3 className="text-[1.8rem] mb-4 md:text-[2rem] font-bold leading-[1.2] md:leading-[1.1]">
                    Acceptable use
                </h3>
                <p>
                    This Website and the Service may only be used within the
                    scope of what they are provided for, under these Terms and
                    applicable law.
                </p>
                <p>
                    Users are solely responsible for making sure that their use
                    of this Website or the Service violates no applicable law,
                    regulations, or third-party rights.
                </p>
                <p>
                    Therefore, the Owner reserves the right to take any
                    appropriate measure to protect its legitimate interests
                    including by denying Users access to this Website or the
                    Service, terminating contracts, reporting any misconduct
                    performed through this Website or the Service to the
                    competent authorities – such as judicial or administrative
                    authorities - whenever Users engage or are suspected to
                    engage in any of the following activities:
                </p>
                <ul className="md:pl-6 pl-3 space-y-2 list-disc list-inside">
                    <li>violate laws, regulations, or these Terms;</li>
                    <li>infringe any third-party rights;</li>
                    <li>
                        considerably impair the Owner’s legitimate interests;
                    </li>
                    <li>offend the Owner or any third party.</li>
                </ul>
                <p>
                    Controls limit misuse of plans resulting in excessive calls.
                </p>
            </section>
            <section className="flex flex-col gap-[1.6rem]">
                <h2 className="text-[2rem] mb-4 md:text-4xl font-bold leading-[1.2] md:leading-[1.1]">
                    Liability and indemnification
                </h2>
                <h3 className="text-[1.8rem] mb-4 md:text-[2rem] font-bold leading-[1.2] md:leading-[1.1]">
                    Australian Users
                </h3>
                <h4 className="text-[1.6rem] mb-4 md:text-[1.8rem] font-bold leading-[1.2] md:leading-[1.1]">
                    Limitation of liability
                </h4>
                <p>
                    Nothing in these Terms excludes, restricts or modifies any
                    guarantee, condition, warranty, right or remedy which the
                    User may have under the Competition and Consumer Act 2010
                    (Cth) or any similar State and Territory legislation and
                    which cannot be excluded, restricted or modified
                    (non-excludable right). To the fullest extent permitted by
                    law, our liability to the User, including liability for a
                    breach of a non-excludable right and liability which is not
                    otherwise excluded under these Terms of Use, is limited, at
                    the Owner’s sole discretion, to the re-performance of the
                    services or the payment of the cost of having the services
                    supplied again.
                </p>
                <h3 className="text-[1.8rem] mb-4 md:text-[2rem] font-bold leading-[1.2] md:leading-[1.1]">
                    US and All Other Users
                </h3>
                <h4 className="text-[1.6rem] mb-4 md:text-[1.8rem] font-bold leading-[1.2] md:leading-[1.1]">
                    Disclaimer of Warranties
                </h4>
                <p>
                    This Website is provided strictly on an “as is” and “as
                    available” basis. Use of the Service is at Users’ own risk.
                    To the maximum extent permitted by applicable law, the Owner
                    expressly disclaims all conditions, representations, and
                    warranties — whether express, implied, statutory, or
                    otherwise, including, but not limited to, any implied
                    warranty of merchantability, fitness for a particular
                    purpose, satisfactory quality, title, or non-infringement of
                    third-party rights. No advice or information, whether oral
                    or written, obtained by user from owner or through the
                    Service will create any warranty not expressly stated
                    herein.
                </p>
                <p>
                    Without limiting the foregoing, the Owner, its subsidiaries,
                    affiliates, licensors, officers, directors, agents,
                    co-branders, partners, suppliers, and employees do not
                    warrant that the content is accurate, reliable, or correct;
                    that the Service will meet Users’ requirements; that the
                    Service will be available at any particular time or
                    location, uninterrupted or secure; that any defects or
                    errors will be corrected; or that the Service is free of
                    viruses or other harmful components. Any content downloaded
                    or otherwise obtained through the use of the Service is
                    downloaded at users own risk and users shall be solely
                    responsible for any damage to Users’ computer system or
                    mobile device or loss of data that results from such
                    download or Users’ use of the Service.
                </p>
                <p>
                    The Owner does not warrant, endorse, guarantee, or assume
                    responsibility for any product or service advertised or
                    offered by a third party through the Service or any
                    hyperlinked website or service, and the Owner shall not be a
                    party to or in any way monitor any transaction between Users
                    and third-party providers of products or services.
                </p>
                <p>
                    The Service may become inaccessible or it may not function
                    properly with Users’ web browser, mobile device, and/or
                    operating system. The owner cannot be held liable for any
                    perceived or actual damages arising from Service content,
                    operation, or use of this Service.
                </p>
                <p>
                    Federal law, some states, and other jurisdictions, do not
                    allow the exclusion and limitations of certain implied
                    warranties. The above exclusions may not apply to Users.
                    This Agreement gives Users specific legal rights, and Users
                    may also have other rights which vary from state to state.
                    The disclaimers and exclusions under this agreement shall
                    not apply to the extent prohibited by applicable law.
                </p>
                <h4 className="text-[1.6rem] mb-4 md:text-[1.8rem] font-bold leading-[1.2] md:leading-[1.1]">
                    Limitations of liability
                </h4>
                <p>
                    To the maximum extent permitted by applicable law, in no
                    event shall the Owner, and its subsidiaries, affiliates,
                    officers, directors, agents, co-branders, partners,
                    suppliers, and employees be liable for
                </p>
                <ul className="md:pl-6 pl-3 space-y-2 list-disc list-inside">
                    <li>
                        any indirect, punitive, incidental, special,
                        consequential, or exemplary damages, including without
                        limitation damages for loss of profits, goodwill, use,
                        data or other intangible losses, arising out of or
                        relating to the use of, or inability to use, the
                        Service; and
                    </li>
                    <li>
                        any damage, loss or injury resulting from hacking,
                        tampering, or other unauthorized access or use of the
                        Service or User account or the information contained
                        therein;
                    </li>
                    <li>any errors, mistakes, or inaccuracies of content;</li>
                    <li>
                        personal injury or property damage, of any nature
                        whatsoever, resulting from User access to or use of the
                        Service;
                    </li>
                    <li>
                        any unauthorized access to or use of the Owner’s secure
                        servers and/or any and all personal information stored
                        therein;
                    </li>
                    <li>
                        any interruption or cessation of transmission to or from
                        the Service;
                    </li>
                    <li>
                        any bugs, viruses, trojan horses, or the like that may
                        be transmitted to or through the Service;
                    </li>
                    <li>
                        any errors or omissions in any content or for any loss
                        or damage incurred as a result of the use of any content
                        posted, emailed, transmitted, or otherwise made
                        available through the Service; or
                    </li>
                    <li>
                        the defamatory, offensive, or illegal conduct of any
                        User or third party.
                    </li>
                </ul>
                <p>
                    In no event shall the Owner, and its subsidiaries,
                    affiliates, officers, directors, agents, co-branders,
                    partners, suppliers and employees be liable for any claims,
                    proceedings, liabilities, obligations, damages, losses or
                    costs in an amount exceeding the amount paid by User to the
                    Owner hereunder in the preceding 12 months, or the period of
                    duration of this agreement between the Owner and User,
                    whichever is shorter. If User did not pay owner any amounts
                    in the preceding months, then Owner's total aggregate
                    liability shall not exceed the greater of: $5 per user and
                    the minimum amount required by law for enforceability.
                </p>
                <p>
                    This limitation of liability section shall apply to the
                    fullest extent permitted by law in the applicable
                    jurisdiction whether the alleged liability is based on
                    contract, tort, negligence, strict liaAres Search API
                    Termsbility, or any other basis, even if company has been
                    advised of the possibility of such damage.
                </p>
                <p>
                    Some jurisdictions do not allow the exclusion or limitation
                    of incidental or consequential damages, therefore the above
                    limitations or exclusions may not apply to User. The terms
                    give User specific legal rights, and User may also have
                    other rights which vary from jurisdiction to jurisdiction.
                    The disclaimers, exclusions, and limitations of liability
                    under the terms shall not apply to the extent prohibited by
                    applicable law.
                </p>
                <h4 className="text-[1.6rem] mb-4 md:text-[1.8rem] font-bold leading-[1.2] md:leading-[1.1]">
                    Indemnification
                </h4>
                <p>
                    The User agrees to defend, indemnify and hold the Owner and
                    its subsidiaries, affiliates, officers, directors, agents,
                    co-branders, partners, suppliers, and employees harmless
                    from and against any and all claims or demands, damages,
                    obligations, losses, liabilities, costs or debt, and
                    expenses, including, but not limited to, legal fees and
                    expenses, arising from
                </p>
                <ul className="md:pl-6 pl-3 space-y-2 list-disc list-inside">
                    <li>
                        User’s use of and access to the Service, including any
                        data or content transmitted or received by User;
                    </li>
                    <li>
                        User’s violation of these terms, including, but not
                        limited to, User’s breach of any of the representations
                        and warranties set forth in these terms;
                    </li>
                    <li>
                        User’s violation of any third-party rights, including,
                        but not limited to, any right of privacy or intellectual
                        property rights;
                    </li>
                    <li>
                        User’s violation of any statutory law, rule, or
                        regulation;
                    </li>
                    <li>
                        any content that is submitted from User’s account,
                        including third party access with User’s unique
                        username, password or other security measure, if
                        applicable, including, but not limited to, misleading,
                        false, or inaccurate information;
                    </li>
                    <li>User’s willful misconduct; or</li>
                    <li>
                        statutory provision by User or its affiliates, officers,
                        directors, agents, co-branders, partners, suppliers and
                        employees to the extent allowed by applicable law.
                    </li>
                </ul>
            </section>
            <section className="flex flex-col gap-[1.6rem]">
                <h2 className="text-[2rem] mb-4 md:text-4xl font-bold leading-[1.2] md:leading-[1.1]">
                    Common provisions
                </h2>
                <h3 className="text-[1.8rem] mb-4 md:text-[2rem] font-bold leading-[1.2] md:leading-[1.1]">
                    No Waiver
                </h3>
                <p>
                    The Owner’s failure to assert any right or provision under
                    these Terms shall not constitute a waiver of any such right
                    or provision. No waiver shall be considered a further or
                    continuing waiver of such term or any other term.
                </p>
                <h3 className="text-[1.8rem] mb-4 md:text-[2rem] font-bold leading-[1.2] md:leading-[1.1]">
                    Service interruption
                </h3>
                <p>
                    To ensure the best possible service level, the Owner
                    reserves the right to interrupt the Service for maintenance,
                    system updates, or any other changes, informing the Users
                    where it deems appropriate.
                </p>
                <p>
                    Within the limits of law, the Owner may also decide to
                    suspend or terminate the Service altogether. If the Service
                    is terminated, the Owner will cooperate with Users to enable
                    them to withdraw Personal Data or information to the extent
                    required by applicable law.
                </p>
                <p>
                    Additionally, the Service might not be available due to
                    reasons outside the Owner’s reasonable control, such as
                    “force majeure” (eg. labor actions, infrastructural
                    breakdowns, or blackouts, etc.).
                </p>
                <h3 className="text-[1.8rem] mb-4 md:text-[2rem] font-bold leading-[1.2] md:leading-[1.1]">
                    Service reselling
                </h3>
                <p>
                    Users may not reproduce, duplicate, copy, sell, resell, or
                    exploit any portion of this Website and of its Service
                    without the Owner’s express prior written permission,
                    granted either directly or through a legitimate reselling
                    program.
                </p>
                <h3 className="text-[1.8rem] mb-4 md:text-[2rem] font-bold leading-[1.2] md:leading-[1.1]">
                    Privacy policy
                </h3>
                <p>
                    To learn more about how we process personal data, you may
                    refer to the privacy policy of this Website at
                    <a href="https://api.traversaal.ai/legal/privacy">
                        api.traversaal.ai/legal/privacy
                    </a>
                </p>
                <h3 className="text-[1.8rem] mb-4 md:text-[2rem] font-bold leading-[1.2] md:leading-[1.1]">
                    Intellectual property rights
                </h3>
                <p>
                    Without prejudice to any more specific provision of these
                    Terms, any intellectual property rights, such as copyrights,
                    trademark rights, patent rights and design rights related to
                    this Website are, as between the parties, the exclusive
                    property of the Owner or its licensors and are subject to
                    the protection granted by applicable laws or international
                    treaties relating to intellectual property.
                </p>
                <p>
                    All trademarks — nominal or figurative — and all other
                    marks, trade names, service marks, word marks,
                    illustrations, images, or logos appearing in connection with
                    this Website are, and remain, as between the parties, the
                    exclusive property of the Owner or its licensors and are
                    subject to the protection granted by applicable laws or
                    international treaties related to intellectual property.
                </p>
                <h3 className="text-[1.8rem] mb-4 md:text-[2rem] font-bold leading-[1.2] md:leading-[1.1]">
                    Changes to these Terms
                </h3>
                <p>
                    The Owner reserves the right to amend or otherwise modify
                    these Terms at any time. In such cases, the Owner will
                    appropriately inform the User of these changes.
                </p>
                <p>
                    Such changes will only affect the relationship with the User
                    for the future.
                </p>
                <p>
                    The continued use of the Service will signify the User’s
                    acceptance of the revised Terms. If Users do not wish to be
                    bound by the changes, they must stop using the Service.
                    Failure to accept the revised Terms, may entitle either
                    party to terminate the Agreement.
                </p>
                <p>
                    The applicable previous version will govern the relationship
                    prior to the User's acceptance. The User can obtain any
                    previous version from the Owner.
                </p>
                <p>
                    If required by applicable law, the Owner will specify the
                    date by which the modified Terms will enter into force.
                </p>
                <h3 className="text-[1.8rem] mb-4 md:text-[2rem] font-bold leading-[1.2] md:leading-[1.1]">
                    Assignment of contract
                </h3>
                <p>
                    The Owner reserves the right to transfer, assign, dispose of
                    by novation, or subcontract any or all rights or obligations
                    under these Terms, taking the User’s legitimate interests
                    into account. Provisions regarding changes of these Terms
                    will apply accordingly.
                </p>
                <p>
                    Users may not assign or transfer their rights or obligations
                    under these Terms in any way, without the written permission
                    of the Owner.
                </p>
                <h3 className="text-[1.8rem] mb-4 md:text-[2rem] font-bold leading-[1.2] md:leading-[1.1]">
                    Contacts
                </h3>
                <p>
                    All communications relating to the use of this Website must
                    be sent using the contact information stated in this
                    document.
                </p>
                <h3 className="text-[1.8rem] mb-4 md:text-[2rem] font-bold leading-[1.2] md:leading-[1.1]">
                    Severability
                </h3>
                <p>
                    Should any provision of these Terms be deemed or become
                    invalid or unenforceable under applicable law, the
                    invalidity or unenforceability of such provision shall not
                    affect the validity of the remaining provisions, which shall
                    remain in full force and effect.
                </p>
                <h4 className="text-[1.6rem] mb-4 md:text-[1.8rem] font-bold leading-[1.2] md:leading-[1.1]">
                    EU Users
                </h4>
                <p>
                    Should any provision of these Terms be or be deemed void,
                    invalid or unenforceable, the parties shall do their best to
                    find, in an amicable way, an agreement on valid and
                    enforceable provisions thereby substituting the void,
                    invalid or unenforceable parts. In case of failure to do so,
                    the void, invalid or unenforceable provisions shall be
                    replaced by the applicable statutory provisions, if so
                    permitted or stated under the applicable law.
                </p>
                <p>
                    Without prejudice to the above, the nullity, invalidity or
                    the impossibility to enforce a particular provision of these
                    Terms shall not nullify the entire Agreement, unless the
                    severed provisions are essential to the Agreement, or of
                    such importance that the parties would not have entered into
                    the contract if they had known that the provision would not
                    be valid, or in cases where the remaining provisions would
                    translate into an unacceptable hardship on any of the
                    parties.
                </p>
                <h4 className="text-[1.6rem] mb-4 md:text-[1.8rem] font-bold leading-[1.2] md:leading-[1.1]">
                    US Users
                </h4>
                <p>
                    Any such invalid or unenforceable provision will be
                    interpreted, construed and reformed to the extent reasonably
                    required to render it valid, enforceable and consistent with
                    its original intent. These Terms constitute the entire
                    Agreement between Users and the Owner with respect to the
                    subject matter hereof, and supersede all other
                    communications, including but not limited to all prior
                    agreements, between the parties with respect to such subject
                    matter. These Terms will be enforced to the fullest extent
                    permitted by law.
                </p>
                <h3 className="text-[1.8rem] mb-4 md:text-[2rem] font-bold leading-[1.2] md:leading-[1.1]">
                    Notice to California Residents
                </h3>
                <p>
                    If you are a California resident, under California Civil
                    Code Section 1789.3, you may contact the Complaint
                    Assistance Unit of the Division of Consumer Services of the
                    California Department of Consumer Affairs in writing at 1625
                    N. Market Blvd., Suite S-202, Sacramento, California 95834,
                    or by telephone at (800) 952-5210 in order to resolve a
                    complaint regarding the Service or to receive further
                    information regarding use of the Service.
                </p>
                <h3 className="text-[1.8rem] mb-4 md:text-[2rem] font-bold leading-[1.2] md:leading-[1.1]">
                    Governing law
                </h3>
                <p>
                    These Terms are governed by the laws of California, USA,
                    without regard to conflict of laws principles.
                </p>

                <h3 className="text-[1.8rem] mb-4 md:text-[2rem] font-bold leading-[1.2] md:leading-[1.1]">
                    Venue of jurisdiction
                </h3>

                <p>
                    The exclusive competence to decide on any controversy
                    resulting from or connected to these Terms lies in Santa
                    Clara County, California, USA.
                </p>
            </section>

            <section className="flex flex-col gap-[1.6rem]">
                <h1 className="text-[3.2rem] md:text-6xl font-bold leading-[1.2] md:leading-[1.1]">
                    Ares Search API Terms
                </h1>
                <p>
                    Welcome, and thank you for your interest in Traversaal.ai,
                    Inc. (“api.traversaal.ai,” “Traversaal” “we,” or “us”) and
                    our website at www.api.traversaal.ai, along with our related
                    API's, websites, networks, applications, mobile
                    applications, and other services provided by us
                    (collectively, the “Service”). This api.traversaal.ai Search
                    API Agreement is a legally binding contract between you
                    (“you”, “your”) and Traversaal.ai regarding your use of the
                    Service.
                </p>
                <h2 className="text-[2.4rem] md:text-4xl font-bold leading-[1.2] md:leading-[1.1]">
                    PLEASE READ THE FOLLOWING TERMS CAREFULLY.
                </h2>
                <p>
                    BY CLICKING “I ACCEPT,” OR BY DOWNLOADING, INSTALLING, OR
                    OTHERWISE ACCESSING OR USING THE SERVICE,YOU AGREE THAT YOU
                    HAVE READ AND UNDERSTOOD, AND, AS A CONDITION TO YOUR USE OF
                    THE SERVICE, YOU AGREE TO BE BOUND BY, THE FOLLOWING TERMS
                    AND CONDITIONS, INCLUDING Traversaal.ai'S PRIVACY POLICY
                    (TOGETHER, THESE “TERMS”). IF YOU ARE NOT ELIGIBLE, OR DO
                    NOT AGREE TO THE TERMS, THEN YOU DO NOT HAVE OUR PERMISSION
                    TO USE THE SERVICE. YOUR USE OF THE SERVICE, AND
                    Traversaal.ai'S PROVISION OF THE SERVICE TO YOU, CONSTITUTES
                    AN AGREEMENT BY Traversaal.ai AND BY YOU TO BE BOUND BY
                    THESE TERMS.
                </p>
                <ol className="md:pl-6 pl-3 space-y-2 list-decimal list-inside">
                    <li>Definitions</li>
                    <ol className="md:pl-8 pl-4 space-y-2 list-decimal list-inside">
                        <li>
                            "API" means the api.traversaal.ai Search API
                            application programming interface, any API
                            Documentation and other API Materials made available
                            to you by api.traversaal.ai, including, without
                            limitation, through the website and pages on the
                            api.traversaal.ai domain (collectively, the
                            “Website”), including any Updates.
                        </li>
                        <li>
                            "API Documentation" means the API documentation made
                            available to you by api.traversaal.ai from time to
                            time, including, without limitation, through the
                            Website, including any Updates.
                        </li>
                        <li>
                            "API Key" means the security key api.traversaal.ai
                            makes available for you to access the API.
                        </li>
                        <li>
                            “API Materials” means any information or data made
                            available to you through the API or by any other
                            means authorized by api.traversaal.ai, and any
                            copied and derivative works thereof, including any
                            Content.
                        </li>
                        <li>
                            "Applications" means any applications developed by
                            you to interact with the API.
                        </li>
                        <li>
                            “Content” means any search results, images, data,
                            third party content, or other content that
                            api.traversaal.ai makes available to you via the
                            api.traversaal.ai Offering.
                        </li>
                        <li>
                            "api.traversaal.ai Marks" means api.traversaal.ai's
                            proprietary trademarks, trade names, branding, or
                            logos made available for use in connection with the
                            API pursuant to this Agreement.
                        </li>
                        <li>
                            "api.traversaal.ai Offering" means
                            api.traversaal.ai's software described at the
                            Website.
                        </li>
                        <li>
                            “Services” means the API made available that
                            provides access to the Content, the
                            api.traversaal.ai Offering, and any API Materials
                            and API Documentation.
                        </li>
                        <li>
                            "Updates" means any updates, bug fixes, patches, or
                            other error corrections to the API that
                            api.traversaal.ai generally makes available free of
                            charge to all licensees of the API.
                        </li>
                        <li>
                            “Website” means the api.traversaal.ai's website at
                            api.traversaal.ai and any subdomains.
                        </li>
                    </ol>
                    <li>
                        License
                        <ol className="md:pl-10 pl-5 space-y-2 list-decimal list-inside">
                            <li>
                                <span className="underline">
                                    License Grants.
                                </span>
                                Subject to and conditioned on your payment of
                                Fees and compliance with all terms and
                                conditions in this Agreement, api.traversaal.ai
                                hereby grants you a limited, revocable,
                                non-exclusive, non-transferable,
                                non-sublicensable license during the term of the
                                Agreement to use the Services to do the
                                following: (i) use the API solely for the
                                purposes of internally developing the
                                Applications that will communicate and
                                interoperate with the api.traversaal.ai
                                Offering; (ii) to Use the API; and (iii) subject
                                to the express prior permission of
                                api.traversaal.ai, display certain
                                api.traversaal.ai Marks in compliance with usage
                                guidelines that api.traversaal.ai may specify
                                from time to time solely in connection with the
                                use of the API and the Applications and not in
                                connection with the advertising, promotion,
                                distribution, or sale of any other products or
                                services. API Materials may be used to answer
                                queries on your website, but not to train AI
                                models, and may not be stored for later re-use.
                            </li>
                            <li>
                                <span className="underline">
                                    Use Restrictions.
                                </span>{" "}
                                You shall not use the Services, the API or any
                                api.traversaal.ai Mark for any purposes beyond
                                the scope of the license granted in this
                                Agreement. Without limiting the foregoing and
                                except as expressly set forth in this Agreement
                                or in any written modification by
                                api.traversaal.ai of this Agreement, you shall
                                not at any time, and shall not permit others to:
                                <ol className="md:pl-12 pl-6 space-y-2 list-decimal list-inside">
                                    <li>
                                        store the results of the API or any
                                        derivative works of the API (which
                                        reference includes, for avoidance of
                                        doubt, any API Materials and Content),
                                        in whole or in part;
                                    </li>
                                    <li>
                                        rent, lease, lend, sell, sublicense,
                                        assign, distribute, publish, transfer,
                                        or otherwise make available the API, in
                                        whole or in part;
                                    </li>
                                    <li>
                                        reverse engineer, disassemble,
                                        decompile, decode, adapt, or otherwise
                                        attempt to derive or gain access to any
                                        software component of the API, in whole
                                        or in part;
                                    </li>
                                    <li>
                                        circumvent or bypass rate limits or
                                        service limits through any method,
                                        including by creating multiple accounts;
                                    </li>
                                    <li>
                                        remove any proprietary notices from the
                                        API;
                                    </li>
                                    <li>
                                        use the API in any manner or for any
                                        purpose that infringes, misappropriates,
                                        or otherwise violates any intellectual
                                        property right or other right of any
                                        person, or that violates any applicable
                                        law;
                                    </li>
                                    <li>
                                        enter into a licensing agreement that
                                        conflicts with this Agreement;
                                    </li>
                                    <li>
                                        use the API in any manner that harms the
                                        api.traversaal.ai, including without
                                        limitation, api.traversaal.ai's
                                        products, services, infrastructure,
                                        brand, or goodwill;
                                    </li>
                                    <li>
                                        combine or integrate the API with any
                                        software, technology, services, or
                                        materials not authorized by
                                        api.traversaal.ai;
                                    </li>
                                    <li>
                                        design or permit the Applications to
                                        disable, override, or otherwise
                                        interfere with any
                                        api.traversaal.ai-implemented
                                        communications to end users, consent
                                        screens, user settings, alerts, warning,
                                        or the like;
                                    </li>
                                    <li>
                                        use the API in any of the Applications
                                        to replicate or attempt to replace the
                                        user experience of the api.traversaal.ai
                                        Offering;
                                    </li>
                                    <li>
                                        attempt to cloak or conceal your
                                        identity or the identity of the
                                        Applications when requesting
                                        authorization to use the API;
                                    </li>
                                    <li>
                                        copy, store, archive, cache, or create a
                                        database of the Content, in whole or in
                                        part;
                                    </li>
                                    <li>
                                        redistribute, resell, or sublicense the
                                        Content;
                                    </li>
                                    <li>
                                        use the Content as part of any machine
                                        learning or similar algorithmic
                                        activity;
                                    </li>
                                    <li>
                                        use the Content to create, train,
                                        evaluate, or improve new or existing
                                        services that the you or third parties
                                        might offer; or
                                    </li>
                                    <li>
                                        upon termination, cancellation,
                                        expiration, or other conclusion of this
                                        Agreement, retain, or fail to destroy,
                                        any and all API Materials in your
                                        possession and control.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span className="underline">
                                    Enterprise Access.
                                </span>{" "}
                                api.traversaal.ai may in writing vary these
                                terms if Customer is offering the Services to
                                enterprise users (“Enterprise Clients”).
                            </li>
                            <li>
                                <span className="underline">
                                    Reservation of Rights.
                                </span>{" "}
                                api.traversaal.ai reserves all rights not
                                expressly granted to you in this Agreement,
                                including the right to change rate limits, the
                                size and structure of request responses, and how
                                much Content is provided in the response. Except
                                for the limited rights and licenses expressly
                                granted under this Agreement, nothing in this
                                Agreement grants to you or any third party, by
                                implication, waiver, estoppel, or otherwise, any
                                intellectual property rights or other right,
                                title, or interest in or to the API.
                            </li>
                        </ol>
                        <li>
                            Your Responsibilities
                            <ol className="md:pl-10 pl-5 space-y-2 list-decimal list-inside">
                                <li>
                                    You are responsible and liable for all uses
                                    of the API resulting from access provided by
                                    you, directly or indirectly, whether such
                                    access or use is permitted by or in
                                    violation of this Agreement. Without
                                    limiting the generality of the foregoing,
                                    you are responsible for all acts and
                                    omissions of your end users in connection
                                    with the Application and their use of the
                                    API, if any. Any act or omission by your end
                                    user that would constitute a breach of this
                                    Agreement if taken by you will be deemed a
                                    breach of this Agreement by you. You shall
                                    make reasonable efforts to make all of your
                                    end users aware of this Agreement's
                                    provisions applicable to such end user's use
                                    of the API and shall cause end users to
                                    comply with such provisions.
                                </li>
                                <li>
                                    You must obtain an API Key from
                                    api.traversaal.ai to use and access the API.
                                    You may not share the API Key with any third
                                    party, must keep the API Key and all log-in
                                    information secure, and must use the API Key
                                    as your sole means of accessing the API. The
                                    API Key may be revoked at any time by
                                    api.traversaal.ai. In the event of an
                                    unauthorized use of the API Key by a third
                                    party, the party from whom unauthorized
                                    access was taken must inform the other party
                                    through the method of Notice set forth in
                                    this Agreement.
                                </li>
                                <li>
                                    You shall comply with all terms and
                                    conditions of this Agreement, all applicable
                                    laws, rules, and regulations, and all
                                    guidelines, standards, and requirements that
                                    api.traversaal.ai may post on this Website,
                                    in any documentation and elsewhere from time
                                    to time. You shall monitor the use of the
                                    Applications for any activity that violates
                                    applicable laws, rules, and regulations or
                                    any terms and conditions of this Agreement,
                                    including any fraudulent, inappropriate, or
                                    potentially harmful behavior, and promptly
                                    restrict any offending users of the
                                    Applications from further use of the
                                    Applications. You are solely responsible for
                                    posting any privacy notices and obtaining
                                    any consents from your end users required
                                    under applicable laws, rules, and
                                    regulations for their use of the
                                    Applications.
                                </li>
                                <li>
                                    You shall not use the API in connection with
                                    or to promote any products, services, or
                                    materials that constitute, promote, or are
                                    used primarily for the purpose of dealing in
                                    spyware, adware, or other malicious programs
                                    or code, counterfeit goods, items subject to
                                    US embargo, unsolicited mass distribution of
                                    email, multi-level marketing proposals, hate
                                    materials, hacking, surveillance,
                                    interception, or descrambling equipment,
                                    libelous, defamatory, obscene, abusive, or
                                    otherwise offensive content, stolen
                                    products, and items used for theft,
                                    hazardous material, or any illegal
                                    activities.
                                </li>
                                <li>
                                    You will use commercially reasonable efforts
                                    to safeguard the API, which reference
                                    includes (for avoidance of doubt) any API
                                    Materials and Content (including, in each
                                    case, all copies of the foregoing) from
                                    infringement, misappropriation, theft,
                                    misuse, disclosure, copying, or unauthorized
                                    access. You will promptly notify
                                    api.traversaal.ai if you become aware of any
                                    infringement of any intellectual property
                                    rights in the API and will fully cooperate
                                    with api.traversaal.ai in any legal action
                                    taken by api.traversaal.ai to enforce
                                    api.traversaal.ai's intellectual property
                                    rights.
                                </li>
                                <li>
                                    You may, in any end product utilizing the
                                    API, use the language “POWERED BY
                                    api.traversaal.ai” to describe the
                                    technology utilized by your product.
                                    api.traversaal.ai reserves the right to
                                    require you to control any use of
                                    api.traversaal.ai Marks on your site
                                    including, for example, by requiring you to
                                    display the api.traversaal.ai Marks, change
                                    the manner of display of api.traversaal.ai
                                    Marks or by revoking permission to display
                                    api.traversaal.ai Marks, or by directing you
                                    to comply with any usage guidelines
                                    api.traversaal.ai may specify from time to
                                    time. Your use of the api.traversaal.ai
                                    Marks in connection with this Agreement will
                                    not create any right, title, or interest in
                                    or to the api.traversaal.ai Marks in favor
                                    of you and all goodwill associated with the
                                    use of the api.traversaal.ai Marks will
                                    inure to the benefit of api.traversaal.ai.
                                    You shall not make any statement regarding
                                    use of the API or use the api.traversaal.ai
                                    Marks in any way that would suggest
                                    partnership with, sponsorship by, or
                                    endorsement by api.traversaal.ai without
                                    api.traversaal.ai's prior written consent,
                                    which may be granted or withheld in
                                    api.traversaal.ai's sole discretion. Unless
                                    we agree otherwise, you agree that we may
                                    use your company or product name,
                                    screenshots of your Application, or other
                                    content or depictions in the course of
                                    marketing, promoting, or demonstrating the
                                    API.
                                </li>
                            </ol>
                        </li>
                    </li>
                    <li>
                        Updates
                        <ol className="md:pl-8 pl-4 space-y-2 list-decimal list-inside">
                            <li>
                                <span className="underline">
                                    Fees; Order Form Terms.
                                </span>{" "}
                                You shall pay api.traversaal.ai the fees
                                ("Fees") set forth on the Website. Fees are due
                                in advance of the applicable subscription term.
                                Some fees correspond to credits for API calls.
                                Fees may be paid electronically via methods made
                                available on the Services. Credits for API calls
                                purchased but unused during a subscription term
                                may continue to be used by you for up to 1 year
                                after termination. Credits are nontransferable
                                and nonrefundable. Your use of credits after
                                termination is subject to all terms of this
                                Agreement.
                            </li>
                            <li>
                                We may change any and all prices at any time
                                with 30 days' notice. All deposits and purchases
                                are non-refundable. You shall make all payments
                                in US dollars on or before the due date, by a
                                means specified by api.traversaal.ai. All
                                payments are non-refundable except as otherwise
                                expressly provided in this Agreement or as
                                required by law. If you fail to pay the Fees
                                when due, api.traversaal.ai may charge interest
                                on the past due amount at the rate of 1.5% per
                                month, calculated daily and compounded monthly,
                                or if lower, the highest rate permitted under
                                applicable law, you shall reimburse
                                api.traversaal.ai for all costs incurred by
                                api.traversaal.ai in collecting any late
                                payments or interest, including attorneys' fees,
                                court costs, and collection agency fees, and
                                api.traversaal.ai may in its discretion prohibit
                                or suspend your access to the API,
                                api.traversaal.ai Offering, Content, and any
                                other Services until all past due amounts and
                                interest have been paid, without incurring any
                                obligation or liability to you or any other
                                person by reason of such suspension or
                                prohibition of access to the API,
                                api.traversaal.ai Offering, Content, and any
                                other Services, or may take any other action
                                permitted by this Agreement, up to and including
                                termination.
                            </li>
                            <li>
                                <span className="underline">Taxes.</span> All
                                Fees and other amounts payable by you under this
                                Agreement are exclusive of taxes and similar
                                assessments. You are responsible for all sales,
                                use, and excise taxes, and any other similar
                                taxes, duties, and charges of any kind imposed
                                by any federal, state, or local governmental or
                                regulatory authority on any amounts payable by
                                you hereunder, other than any taxes imposed on
                                api.traversaal.ai's income. If any taxes are to
                                be withheld on payments you make to
                                api.traversaal.ai, you may deduct such taxes
                                from the amount owed to api.traversaal.ai and
                                pay them to the appropriate taxing authority.
                                You must promptly secure and deliver an official
                                receipt for those withholdings and other
                                documents api.traversaal.ai reasonably requests
                                to claim a foreign tax credit or refund. You
                                must ensure that any taxes withheld are
                                minimized to the extent possible under
                                applicable law.
                            </li>
                        </ol>
                    </li>
                    <li>Collections and Use of Information; Interruptions</li>
                    <li>
                        api.traversaal.ai may collect certain information
                        through the API or the api.traversaal.ai Offering about
                        you or any of your employees, contractors, or agents. By
                        accessing, using, and providing information to or
                        through the API or the api.traversaal.ai Offering, you
                        consent to all actions taken by api.traversaal.ai with
                        respect to your information in compliance with the
                        then-current version of api.traversaal.ai's privacy
                        policy and data protection requirements, available at
                        api.traversaal.ai/legal/privacy. 6.2 Our Services are
                        operational 24 hours a day and 7 days a week. You
                        recognize that this accessibility may however be
                        interrupted in case of technical difficulties or force
                        majeure (see below). api.traversaal.ai shall not be held
                        responsible for network or power failures, which would
                        impede or degrade access to our Services. We reserve the
                        right to interrupt, suspend momentarily, or modify
                        without prior notice access to all or parts of the
                        Services. Our Services are evolving. As such, the
                        Services may change from time to time, at our
                        discretion.Intellectual Property Ownership;
                        Confidentiality.
                    </li>
                    <li>
                        You acknowledge that, as between you and
                        api.traversaal.ai, api.traversaal.ai and its licensors
                        own all rights, title, and interest, including all
                        intellectual property rights, in and to the API
                        (including API Documentation, API Materials and any
                        Content), the api.traversaal.ai Offering, and the
                        api.traversaal.ai Marks. The terms of this Agreement
                        (including without limitation pricing) and API
                        Materials, API Documentation, and any software or other
                        technology related to the Services are the confidential
                        information of api.traversaal.ai. You may not disclose
                        nor use any api.traversaal.ai confidential information
                        except as expressly provided in this Agreement.Feedback.
                    </li>
                    <li>
                        If you or any of your employees, contractors, and agents
                        send or transmit any communications or materials to
                        api.traversaal.ai by mail, email, telephone, or
                        otherwise, suggesting or recommending changes to the
                        API, the api.traversaal.ai Offering, or the
                        api.traversaal.ai Marks, including without limitation,
                        new features or functionality, or any comments,
                        questions, suggestions, or the like (“Feedback”), all
                        such Feedback is and will be treated as non-confidential
                        and you hereby assign to api.traversaal.ai on your
                        behalf, and on behalf of your employees, contractors,
                        and agents, all right, title, and interest in, and
                        api.traversaal.ai is free to use, without any
                        attribution or compensation to you or any third party,
                        any ideas, know-how, concepts, techniques, or other
                        intellectual property rights contained in the Feedback,
                        for any purpose whatsoever. api.traversaal.ai is not
                        required to use any Feedback.Disclaimer of Warranties.
                    </li>
                    <li>
                        You use the Services entirely at your own risk. THE
                        SERVICES ARE PROVIDED "AS IS,” “WHERE IS,” “AS
                        AVAILABLE,” WITH ALL FAULTS, AND api.traversaal.ai
                        SPECIFICALLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS,
                        IMPLIED, STATUTORY, OR OTHERWISE. api.traversaal.ai
                        SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF
                        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                        TITLE, SATISFACTORY QUALITY, AND NON-INFRINGEMENT, AND
                        ALL WARRANTIES ARISING FROM COURSE OF DEALING, USAGE, OR
                        TRADE PRACTICE. api.traversaal.ai MAKES NO WARRANTY OF
                        ANY KIND THAT THE API, CONTENT, api.traversaal.ai
                        OFFERINGS, OR api.traversaal.ai MARKS, OR ANY PRODUCTS
                        OR RESULTS OF THEIR USE, WILL MEET YOUR OR ANY OTHER
                        PERSON'S REQUIREMENTS, OPERATE WITHOUT INTERRUPTION,
                        ACHIEVE ANY INTENDED RESULT, BE COMPATIBLE OR WORK WITH
                        ANY OF YOUR OR ANY THIRD PARTY'S SOFTWARE, SYSTEM, OR
                        OTHER SERVICES, OR BE SECURE, ACCURATE, COMPLETE, FREE
                        OF HARMFUL CODE, OR ERROR-FREE, OR THAT ANY ERRORS OR
                        DEFECTS CAN OR WILL BE CORRECTED. api.traversaal.ai IS
                        NOT RESPONSIBLE FOR THE ACCURACY OR COMPLETENESS OF ANY
                        DATA, INCLUDING WITHOUT LIMITATION IN ANY CONTENT.
                        api.traversaal.ai does not warrant that the Services
                        will operate uninterrupted or error free, that all
                        errors will be corrected, that api.traversaal.ai will
                        review your data for accuracy, or that it will maintain
                        your data without loss. You may have other statutory
                        rights, but any statutorily required warranties will be
                        limited to the shortest legally permitted
                        period.Indemnification.
                    </li>
                    <li>
                        You agree to indemnify, defend, and hold harmless
                        api.traversaal.ai and its officers, directors,
                        employees, agents, affiliates, successors, and assigns
                        from and against any and all losses, damages,
                        liabilities, deficiencies, claims, actions, judgments,
                        settlements, interest, awards, penalties, fines, costs,
                        or expenses of whatever kind, including attorneys' fees,
                        arising from or relating to (a) your use or misuse of
                        the API, api.traversaal.ai Offering, Content, or
                        api.traversaal.ai Marks, (b) your breach of this
                        Agreement, and (c) the Applications, including any end
                        user's use thereof. In the event api.traversaal.ai seeks
                        indemnification or defense from you under this
                        provision, api.traversaal.ai will promptly notify you in
                        writing of the claim(s) brought against
                        api.traversaal.ai for which api.traversaal.ai seeks
                        indemnification or defense. api.traversaal.ai reserves
                        the right, at api.traversaal.ai's option and in
                        api.traversaal.ai's sole discretion at your expense, to
                        assume full control of the defense of claims with legal
                        counsel of api.traversaal.ai's choice. You may not enter
                        into any third-party agreement that would, in any manner
                        whatsoever, constitute an admission of fault by
                        api.traversaal.ai or bind api.traversaal.ai in any
                        manner, without api.traversaal.ai's prior written
                        consent. In the event api.traversaal.ai assumes control
                        of the defense of such claim, api.traversaal.ai will not
                        settle any such claim requiring payment from you without
                        your prior written approval.Limitations of Liability.
                    </li>
                    <li>
                        TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, IN
                        NO EVENT WILL api.traversaal.ai BE LIABLE TO YOU OR TO
                        ANY THIRD PARTY UNDER ANY TORT, CONTRACT, NEGLIGENCE,
                        STRICT LIABILITY, OR OTHER LEGAL OR EQUITABLE THEORY FOR
                        (a) ANY LOST PROFITS, LOST OR CORRUPTED DATA, COMPUTER
                        FAILURE OR MALFUNCTION, INTERRUPTION OF BUSINESS, OR ANY
                        SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, OR
                        CONSEQUENTIAL DAMAGES OF ANY KIND ARISING OUT OF THE USE
                        OR INABILITY TO USE THE API OR OTHERWISE UNDER THIS
                        AGREEMENT; OR (b) ANY DAMAGES, IN THE AGGREGATE, IN
                        EXCESS OF THE AMOUNT OF FEES PAID BY YOU TO
                        api.traversaal.ai IN THE 12 MONTHS PRECEDING THE DATE OF
                        THE CLAIM. THE LIMITATIONS IN THIS SECTION APPLY EVEN IF
                        api.traversaal.ai HAS BEEN ADVISED OF THE POSSIBILITY OF
                        SUCH LOSS OR DAMAGES AND WHETHER OR NOT SUCH LOSS OR
                        DAMAGES ARE FORESEEABLE OR api.traversaal.ai WAS ADVISED
                        OF THE POSSIBILITY OF SUCH DAMAGES. ANY CLAIM YOU MAY
                        HAVE ARISING OUT OF OR RELATING TO THIS AGREEMENT MUST
                        BE BROUGHT WITHIN THREE MONTHS AFTER THE OCCURRENCE OF
                        THE EVENT GIVING RISE TO THE CLAIM.Term and Termination.
                        <ol className="md:pl-8 pl-4 space-y-2 list-decimal list-inside">
                            <li>
                                <span className="underline">Term. </span>
                                The term of this Agreement begins upon License's
                                acceptance until termination.
                            </li>
                            <li>
                                <span className="underline">Suspension.</span>
                                api.traversaal.ai may suspend your use of and
                                access to the API, Content, api.traversaal.ai
                                Offering, or other Services immediately without
                                notice, (i) if api.traversaal.ai reasonably
                                believes that (A) your use would cause damage
                                to, or represent an inordinate burden on,
                                api.traversaal.ai; (B) you have violated any
                                term of this Agreement; (C) you creates risk or
                                possible legal exposure for api.traversaal.ai
                                (in api.traversaal.ai's sole discretion); (D)
                                the provision of the API, api.traversaal.ai
                                Offering, or Services are no longer commercially
                                viable (in api.traversaal.ai's sole discretion);
                                (ii) for prolonged inactivity; (iii) if
                                api.traversaal.ai is requested or directed to do
                                so by any competent court of law or government
                                authority; or (iv) for failure to pay for the
                                Services. api.traversaal.ai shall have no
                                liability for damages of any sort arising out of
                                or resulting from any suspension for the reasons
                                set out in this Section 12(b)
                            </li>

                            <li>
                                <span className="underline">Termination. </span>
                                Subscriptions will continue until terminated.
                                you may terminate this agreement at any time by
                                providing notice to api.traversaal.ai, such as
                                by indicating its desire to cancel its
                                subscription in its account settings.
                                api.traversaal.ai reserves the right to
                                terminate any subscription at any time with or
                                without notice to you, for any reason or no
                                reason, in its sole and absolute discretion.
                            </li>
                            <li>
                                <span className="underline">Effect </span>
                                of Expiration or Termination. Upon expiration or
                                termination of this Agreement for any reason all
                                licenses and rights granted to you under this
                                Agreement will also terminate and you must cease
                                using, destroy, and permanently erase all copies
                                of the API and api.traversaal.ai Marks from all
                                devices and systems you directly or indirectly
                                controls. All outstanding payments, if any, will
                                be automatically become due and payable.
                            </li>
                            <li>
                                <span className="underline">Survival. </span>
                                Any terms that by their nature are intended to
                                continue beyond the termination or expiration of
                                this Agreement, including without limitation
                                Sections 2(b), 2(d), 3(a), 3(c), 3(d), 3(e), 5,
                                6, 7, 8, 9, 10, 11, 12(b), 12(d), 12(e), 13,
                                will survive termination.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Miscellaneous
                        <ol className="md:pl-8 pl-4 space-y-2 list-decimal list-inside">
                            <li>
                                <span className="underline">
                                    Entire Agreement.{" "}
                                </span>
                                This Agreement, together with any other
                                documents incorporated by reference, constitutes
                                the sole and entire agreement of the Parties
                                with respect to the subject matter of this
                                Agreement and supersedes all prior and
                                contemporaneous understandings, agreements, and
                                representations and warranties, both written and
                                oral, with respect to such subject matter. In
                                the event of any inconsistency between the
                                statements made in the body of this Agreement
                                and any other documents incorporated herein by
                                reference, this Agreement presides over any
                                other documents incorporated herein by
                                reference.
                            </li>
                            <li>
                                <span className="underline">
                                    Damages Not an Adequate Remedy.
                                </span>{" "}
                                The parties declare that it is impossible to
                                measure in money the damages that will accrue to
                                api.traversaal.ai by reason of a failure by you
                                to perform any of the obligations under this
                                Agreement and therefore injunctive relief is
                                appropriate. Any breach or threatened breach of
                                this Agreement, and, in particular, of Section 2
                                and Section 3, may, in some cases, give rise to
                                irreparable harm to api.traversaal.ai, for which
                                monetary damages would not be an adequate
                                remedy. As such, you agree that
                                api.traversaal.ai shall, in addition to its
                                other rights and remedies, at law or in equity,
                                that may be available in respect of such breach,
                                be entitled to equitable relief, including a
                                temporary restraining order, injunction,
                                specific performance, or any other relief that
                                may be available from a court of competent
                                jurisdiction. If api.traversaal.ai shall
                                institute any action or proceeding to enforce
                                the provisions of this Agreement, you hereby
                                agrees that api.traversaal.ai shall be entitled
                                to apply for any available remedy and
                                irrevocably waives the claim or defense that
                                such party has an adequate remedy at law, and
                                you shall not urge in any such action or
                                proceeding the claim or defense that such party
                                has an adequate remedy at law.
                            </li>
                            <li>
                                <span className="underline">
                                    Force Majeure.
                                </span>{" "}
                                api.traversaal.ai will not be in default if
                                performance is delayed or prevented for reasons
                                beyond its control, so long as it resumes
                                performance as soon as practical.
                            </li>
                            <li>
                                <span className="underline">
                                    No Joint Venture.{" "}
                                </span>
                                api.traversaal.ai and you are acting as
                                independent contractors, and nothing in this
                                Agreement will be construed as creating a
                                partnership, franchise, joint venture,
                                employer-employee or agency relationship.
                            </li>
                            <li>
                                <span className="underline">Notices. </span>
                                All notices under this Agreement (each, a
                                "Notice") from you to api.traversaal.ai must be
                                delivered to 5960 S Land Park Dr #1420
                                Sacramento, CA 95822 by overnight mail. All
                                Notices from api.traversaal.ai to you will be
                                electronically delivered to the email address
                                associated with your Traversaal.ai Search API
                                account. If you do not consent to receive
                                Notices electronically, you must stop using
                                api.traversaal.ai's Offering. Except as
                                otherwise provided in this Agreement, a Notice
                                is effective only: (i) upon receipt by the
                                receiving party, and (ii) if the party giving
                                the Notice has complied with the requirements of
                                this Section.
                            </li>
                            <li>
                                <span className="underline">
                                    Amendment and Modification; Waiver.{" "}
                                </span>
                                No amendment to or modification of this
                                Agreement is effective unless it is in writing
                                and signed by an authorized representative of
                                each party. No waiver by any party of any of the
                                provisions will be effective unless explicitly
                                set forth in writing and signed by the party so
                                waiving. Except as otherwise set forth in this
                                Agreement, (i) no failure to exercise, or delay
                                in exercising, any rights, remedy, power, or
                                privilege arising from this Agreement will
                                operate or be construed as a waiver thereof and
                                (ii) no single or partial exercise of any right,
                                remedy, power, or privilege hereunder will
                                preclude any other or further exercise thereof
                                or the exercise of any other right, remedy,
                                power, or privilege.
                            </li>
                            <li>
                                <span className="underline">
                                    Severability.{" "}
                                </span>
                                If any provision of this Agreement is invalid,
                                illegal, or unenforceable in any jurisdiction,
                                such invalidity, illegality, or unenforceability
                                will not affect any other term or provision of
                                this Agreement or invalidate or render
                                unenforceable such term or provision in any
                                other jurisdiction. Upon such determination that
                                any term or other provision is invalid, illegal,
                                or unenforceable, the Parties shall negotiate in
                                good faith to modify this Agreement so as to
                                affect the original intent of the Parties as
                                closely as possible in a mutually acceptable
                                manner in order that the transactions
                                contemplated by this Agreement be consummated as
                                originally contemplated to the greatest extent
                                possible.
                            </li>
                            <li>
                                <span className="underline">
                                    Governing Law and Jurisdiction.{" "}
                                </span>
                                This agreement is governed by and construed in
                                accordance with the internal laws of the State
                                of California without giving effect to any
                                choice or conflict of law provision or rule that
                                would require or permit the application of the
                                laws of any jurisdiction other than those of the
                                State of California. The United Nations
                                Convention for the International Sale of Goods
                                does not apply to this Agreement. Any legal
                                suit, action, or proceeding arising out of or
                                related to this agreement or the licenses
                                granted under this Agreement will be instituted
                                exclusively in the federal courts of the United
                                States or the courts of the State of California,
                                in each case located in the city and County of
                                Santa Clara, and each party irrevocably submits
                                to the exclusive jurisdiction of such courts in
                                any such suit, action, or proceeding.
                            </li>
                            <li>
                                <span className="underline">Assignment. </span>
                                You may not assign or transfer any of your
                                rights or delegate any of your obligations
                                hereunder, in each case whether voluntarily,
                                involuntarily, by operation of law, or
                                otherwise, without the prior written consent of
                                api.traversaal.ai, which consent shall not be
                                unreasonably withheld, conditioned, or delayed.
                                Any purported assignment, transfer, or
                                delegation in violation of this Section is null
                                and void. api.traversaal.ai may assign or
                                transfer all rights and obligations hereunder
                                fully or partially without notice to you.
                            </li>
                        </ol>
                    </li>
                </ol>
                <p>Latest update: January 31st, 2024</p>
            </section>
        </PageLayout>
    );
};
export default Terms;
