import React, { createContext, useContext, useState } from 'react';

const StripeContext = createContext({ clientSecret: null, setClientSecret: null, customerID: null, setCustomerID: null });

export const StripeProvider = ({ children }) => {
  const [clientSecret, setClientSecret] = useState(null);
  const [customerID, setCustomerID] = useState(null);

  return (
    <StripeContext.Provider value={{ clientSecret, setClientSecret, customerID, setCustomerID }}>
      {children}
    </StripeContext.Provider>
  );
};


export const useStripeContext = () => {
  const { clientSecret, setClientSecret, customerID, setCustomerID } = useContext(StripeContext);
  if (!setClientSecret || !setCustomerID) {
    throw new Error('useStripeContext must be called in a child of StripProvider')
  }

  return { clientSecret, setClientSecret, customerID, setCustomerID }
}
