import { useState } from "react";
import Button from "./Button";
import Input from "./Input";
import { toast } from "react-hot-toast";

const jobRoles = [
    {
        id: 0,
        value: "",
        label: "Please Select",
    },
    {
        id: 1,
        value: "product",
        label: "Product",
    },
    {
        id: 2,
        value: "engineering",
        label: "Engineering",
    },
    {
        id: 3,
        value: "marketing",
        label: "Marketing",
    },
    {
        id: 4,
        value: "success",
        label: "Customer Success",
    },
    {
        id: 5,
        value: "sales",
        label: "Sales",
    },
    {
        id: 6,
        value: "devrel",
        label: "Developer Relations",
    },
    {
        id: 7,
        value: "support",
        label: "Support",
    },
    {
        id: 8,
        value: "exec",
        label: "Executive",
    },
    {
        id: 9,
        value: "other",
        label: "Everyone",
    },
];

const ContactForm = () => {
    const [form, setForm] = useState({
        name: "",
        company: "",
        email: "",
        jobRole: "",
        message: "",
        loading: false,
    });

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

const handleSubmit = (e) => {
    e.preventDefault();

    const { name, company, email, jobRole, message } = form;
    const subject = encodeURIComponent("Contact Form Submission");
    const body = encodeURIComponent(
        `Name: ${name}%0D%0ACompany: ${company}%0D%0AEmail: ${email}%0D%0AJob Role: ${jobRole}%0D%0AMessage: ${message}`
    );

    const mailtoUrl = `mailto:support@traversaal.ai?subject=${subject}&body=${body}`;

    // Open the default email client with the pre-filled information
    window.open(mailtoUrl, "_blank");

    // Clear form
    setForm({
        name: "",
        company: "",
        email: "",
        jobRole: "",
        message: "",
        loading: false,
    });

    // Inform the user to send the email manually
    toast.success("Thank you! Our team will be in touch soon.");
};

return (
    <form
        onSubmit={handleSubmit}
        className="bg-white w-[90vw] md:max-w-[500px] mx-auto text-black flex flex-col gap-6 mt-12 rounded-[2rem] text-lg py-[4.8rem] px-[4rem]"
    >
        <h3 className="text-blackish text-center text-[2.8rem] font-bold">
            Get In Touch
        </h3>

        <Input
            label="Name"
            name="name"
            value={form.name}
            onChange={handleChange}
            placeholder="Enter your name"
            required
        />

        <Input
            label="Company Name"
            name="company"
            value={form.company}
            onChange={handleChange}
            placeholder="Enter your company name"
            required
        />

        <Input
            label="Email Address"
            name="email"
            value={form.email}
            onChange={handleChange}
            placeholder="Enter your email address"
            required
        />
        <div className="flex flex-col gap-2">
            <label htmlFor="jobRole" className="font-semibold">
                Job Role{" "}
            </label>
            <select
                className="border bg-transparent placeholder:text-black/80 border-blackish/[0.08] focus:outline-none focus:border-blackish/50 rounded-[1rem] py-6 px-[2.5rem] text-lg"
                onChange={handleChange}
                required
                value={form.jobRole}
                name="jobRole"
            >
                {jobRoles.map((jobRole) => (
                    <option
                        className="text-lg"
                        key={jobRole.id}
                        value={jobRole.value}
                    >
                        {jobRole.label}
                    </option>
                ))}
            </select>
        </div>

        <Input
            label="How can we help you?"
            name="message"
            value={form.message}
            onChange={handleChange}
            placeholder="Enter your message"
            required
            type="textarea"
            rows={5}
        />

        <Button
            className="mt-4 text-xl"
            text={form.loading ? "Sending..." : "Send Message"}
        />
    </form>
);
};
export default ContactForm;
