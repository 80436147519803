import React from "react";
import { NavLink } from "react-router-dom";

export const MobileNavBarTab = ({ path, label, handleClick, newTab }) => {
    return (
        <NavLink
            onClick={handleClick}
            to={path}
            target={newTab ? "_blank" : "_self"}
            end
            className={({ isActive }) =>
                "mobile-nav-bar__tab " +
                (isActive ? "mobile-nav-bar__tab--active" : "")
            }
        >
            {label}
        </NavLink>
    );
};
