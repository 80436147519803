import cn from "../utils/cn";

const Button = ({ text, className, onClick, ...rest }) => {
    return (
        <button
            className={cn(
                "px-[3.2rem] text-white shrink-0 h-[4.8rem] min-w-[4.4rem] select-none font-bold bg-primary rounded-md bg-accent transition-all duration-100 focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 focus:ring-2 focus:ring-offset-2 focus:ring-accent/80",
                !rest.disabled && "hover:bg-accent/80",
                className
            )}
            {...rest}
            onClick={onClick}
        >
            {text}
        </button>
    );
};
export default Button;
