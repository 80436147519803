import Button from "./Button";
import { useAuth0 } from "@auth0/auth0-react";

const Hero = () => {
    const { loginWithRedirect } = useAuth0();

    const handleLogin = async () => {
        await loginWithRedirect({
            appState: {
                returnTo: "/dashboard",
            },
            authorizationParams: {
                prompt: "login",
                screen_hint: "signup",
            },
            scope: "openid profile email offline_access",
        });
    };

    return (
        <section className="text-body py-4 max-sm:pt-[3rem]">
            <div className="container flex flex-col items-center justify-center gap-6 mx-auto text-center">
                <h1 className="text-[3rem] md:text-[6rem] md:max-w-[25ch] text-white font-bold leading-[3.6rem] md:leading-[7.2rem]">
                    SOTA Online LLMs!
                </h1>
                <p className="mb-6 max-w-[76.8rem] text-[1.6rem] md:text-[2rem]">
                    Unleash unprecedented speed with our {/*industry-*/}leading Online LLM ARES API. 
                    We offer lightning-fast, real-time search results with URLs in 3-4 seconds. 
                    Free for now; priced solutions coming soon! 
                    
                </p>

                <Button
                    text={"Get 100 free queries"}
                    className="mt-4"
                    onClick={handleLogin}
                />

                <p className="-mt-2 text-[1.4rem]">No credit card required</p>
            </div>
        </section>
    );
};
export default Hero;
