import StripeConfirmationBlock from "../../StripeConfirmationBlock";
import { PageLayout } from "../../page-layout";

const ConfirmationPage = () => {
    return (
        <PageLayout>
            <div className="flex flex-col items-center justify-center w-full h-full gap-20 text-black">
                <StripeConfirmationBlock />
            </div>
        </PageLayout>
    );
};
export default ConfirmationPage;
