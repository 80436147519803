import React from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useAuth0 } from "@auth0/auth0-react";
import { MobileNavBarTab } from "./mobile-nav-bar-tab";

export const MobileNavBarTabs = ({ handleClick }) => {
  const oktaAuth = useOktaAuth();
  const oktaIsAuthenticated = oktaAuth?.authState?.isAuthenticated;

  const auth0Auth = useAuth0();
  const auth0IsAuthenticated = auth0Auth?.isAuthenticated;

  let isAuthenticated;

  if (process.env.REACT_APP_AUTH_PROVIDER === "Okta") {
    isAuthenticated = oktaIsAuthenticated;
  } else if (process.env.REACT_APP_AUTH_PROVIDER === "Auth0") {
    isAuthenticated = auth0IsAuthenticated;
  }

  return (
      <div className="mobile-nav-bar__tabs">
          {isAuthenticated && (
              <>
                  {/* <MobileNavBarTab
            path="/settings"
            label="Settings"
            handleClick={handleClick}
          /> */}
                  <MobileNavBarTab
                      path="/dashboard"
                      label="Dashboard"
                      handleClick={handleClick}
                  />
                  <MobileNavBarTab
                      path="/keys"
                      label="Keys"
                      handleClick={handleClick}
                  />
                  {/* <NavBarTab path="/product-select" label="Billing" /> */}
                  <MobileNavBarTab
                      path="/product-select"
                      label="Billing"
                      handleClick={handleClick}
                  />

                  <MobileNavBarTab
                      path="/playground"
                      label="Playground"
                      handleClick={handleClick}
                  />

                  <MobileNavBarTab
                      path="https://docs.traversaal.ai/docs/intro"
                      label="Docs"
                      newTab
                  />
              </>
          )}
      </div>
  );
};
