import React from "react";
import { NavBar } from "./navigation/desktop/nav-bar";
import { MobileNavBar } from "./navigation/mobile/mobile-nav-bar";
import Footer from "./Footer";
import { Toaster } from "react-hot-toast";
import cn from "../utils/cn";

export const PageLayout = ({ hideNavTabs, children, className }) => {
    return (
        <div className="page-layout">
            <Toaster
                position="bottom-right"
                reverseOrder={false}
                toastOptions={{
                    style: {
                        fontSize: "14px",
                    },
                }}
            />
            <NavBar hideNavTabs={hideNavTabs} />
            <MobileNavBar hideNavTabs={hideNavTabs} />
            <div className={cn("page-layout__content", className)}>
                {children}
            </div>
            <Footer />
        </div>
    );
};
