import { useEffect, useMemo } from "react";
import PricingCard from "./PricingCard";
import { useAuth0 } from "@auth0/auth0-react";
import { useStripeContext } from "../../StripeProvider";

const Pricing = () => {
    const { isAuthenticated, isLoading, user, getAccessTokenSilently } = useAuth0();
    const { setClientSecret } = useStripeContext();


    useEffect(() => {
        const fetchClientSecret = async() => {
            if (!user || isLoading) {
                return;
            }
            const jwt = await getAccessTokenSilently();
            if (!jwt) {
                return;
            }
            const headers = {
                Authorization: `Bearer ${jwt}`
            }
            const customer_url = `${process.env.REACT_APP_DEV_PORTAL_API_SERVER}/stripe/customer?email=${encodeURIComponent(user.email)}`;
            const res = await fetch(customer_url, {
                method: 'GET',
                headers,
            });
            if (!res.ok || res.status !== 200) {
                console.log('BAD REQUEST... ', res.status, res.statusText)
                return
            }
    
            const customer = await res.json();

            if (!customer?.id) {
                return;
            }
            const session_url = `${process.env.REACT_APP_DEV_PORTAL_API_SERVER}/stripe/customer_session?customerId=${encodeURIComponent(customer.id)}`;
            const res2 = await fetch(session_url, {
                method: 'GET',
                headers
            })
            if (!res2.ok || res2.status !== 200) {
                const error = await res2.json();
                console.log(error)
                return
            }
            const client_secret = await res2.json();
            if (client_secret) {
                setClientSecret(client_secret)
            }
            return;
        }

        fetchClientSecret()
    }, [user, isLoading, setClientSecret, getAccessTokenSilently])



    const PRICING = useMemo(
        () => [
            {
                id: 1,
                active: !isAuthenticated,
                title: "Trial AI",
                price: "Free",
                period: "",
                list: [
                    "100 Calls (Lifetime)",
                    "Business Hours Support",
                    "For Unlimited Time",
                ],
                buttonText: "Sign Up",
                type: "TRIAL"
            },
            {
                id: 2,
                active: true,
                title: "Starter",
                price: "$5",
                // period: "per month",
                list: [
                    "200 Calls (Lifetime)",
                    "Business Hours Support",
                    "Free for 30 Days",
                ],
                buttonText: "Purchase",
                type: "STARTER"
            },
            {
                id: 3,
                active: true,
                title: "Enterprise",
                price: "Request quote",
                // period: "per month",
                list: [
                    "50,000 Calls (Monthly)",
                    "Business Hours Support",
                    "Free for 30 Days",
                ],
                buttonText: "Contact Us",
                type: "ENTERPRISE"
            },
        ],
        []
    );


    return (
        <section>
            <div className="container flex flex-col items-center justify-center gap-8 mx-auto text-center">
                <h2
                    className={`text-[3rem] md:text-[4.8rem] text-white font-bold leading-[3.6rem] md:leading-[7.2rem]
                `}
                >
                    Simple, Affordable Pricing
                </h2>
                <p className="mb-6 max-w-[76.8rem] text-[1.6rem] md:text-[2rem]">
                    Experience the industry's lowest prices with our ARES API's
                    top-up model. No monthly subscriptions, just pay for the
                    credits you need. The more you order, the lower the cost per
                    query. The pricing and other options will open up soon.
                    <span>
                        Contact us at{" "}
                        <a
                            className="text-accent underline"
                            href="mailto:sales@traversaal.ai"
                        >
                            sales@traversaal.ai
                        </a>{" "}
                        for getting access to enterprise version.
                    </span>
                </p>
            </div>


            <div className="flex my-16 gap-[2rem] justify-center flex-wrap">
                {PRICING.map((item) => {
                    return (
                        <PricingCard
                            buttonText={item.buttonText}
                            key={item.id}
                            title={item.title}
                            price={item.price}
                            active={item.active}
                            period={item.period}
                            list={item.list}
                            type={item.type}
                        />
                    );
                })}
            </div>
        </section>
    );
};
export default Pricing;
