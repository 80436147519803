const CheckboxIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path
                    fill="currentColor"
                    d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm5.94,6.83-7.5,8.5a1.26,1.26,0,0,1-.9.42h0a1.22,1.22,0,0,1-.88-.37l-2.5-2.5a1.24,1.24,0,1,1,1.76-1.76l1.56,1.56,6.62-7.51a1.25,1.25,0,0,1,1.88,1.66Z"
                />
            </g>
        </svg>
    );
};

export default CheckboxIcon;
