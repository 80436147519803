import Button from "./Button";
import { useAuth0 } from "@auth0/auth0-react";

const ReadyToBuild = () => {
    const { loginWithRedirect } = useAuth0();

    const handleLogin = async () => {
        await loginWithRedirect({
            appState: {
                returnTo: "/dashboard",
            },
            authorizationParams: {
                prompt: "login",
                screen_hint: "signup",
            },
            scope: "openid profile email offline_access",
        });
    };

    return (
        <section className="py-[4.8rem]">
            <div className="w-full px-[2.4rem] mx-auto text-center text-white">
                <h2 className="text-[3rem] md:text-[4.8rem] font-bold">
                    Ready to build?
                </h2>
                <p className="mt-[1.6rem] md:max-w-[65ch] mx-auto text-[1.6rem] md:text-[1.8rem]">
                    Unleash the potential of multiple website search with our
                    high-performance Ares API. Empower your next AI chatbot, SEO
                    analytics, and fintech projects with lightning-fast access
                    to real-time search results.
                </p>
                <div className="flex flex-col md:flex-row justify-center mt-[2.4rem] gap-[1.6rem]">
                    <Button
                        text="Contact us"
                        className="hover:bg-white hover:text-black bg-transparent border border-white"
                    ></Button>
                    <Button
                        text="Try 100 queries for free"
                        onClick={loginWithRedirect}
                    />
                </div>
            </div>
        </section>
    );
};
export default ReadyToBuild;
